import { Component } from '@angular/core';


@Component({
  selector: 'app-page-notfound',
  templateUrl: './notfound.page.html',
  styleUrls: ['./notfound.page.scss'],
})
export class NotFoundPage {

  constructor(
  ) {
  }
}
