import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';

import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Customer } from '../../../shared/models/customer';
import { Location } from '../../../shared/models/location';
import { Address } from '../../../shared/models/address';
import { CheckIn } from '../../../shared/models/check-in';
import { BasePage } from '../../../shared/base.page';
import { AnalyticsManager } from '../../../shared/manager/analytics-manager.service';
import { CheckInManager } from '../../../shared/manager/check-in-manager.service';
import { LocationManager } from '../../../shared/manager/location-manager.service';
import { Session } from '../../../shared/businessobject/session.service';
import { AddressEditComponent } from '../../../shared/modal/address-edit/address-edit.component';
import { LocationPlace } from '../../../shared/models/location-place';


@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.page.html',
  styleUrls: ['./check-in.page.scss'],
})
export class CheckInPage extends BasePage implements OnInit {

  public static readonly SESSION_CHECK_IN = 'checkIn';

  public location?: Location | null;
  public currentCustomer?: Customer;

  private dataForm: FormGroup;

  constructor(
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private session: Session,
    private checkInManager: CheckInManager,
    private locationManager: LocationManager
  ) {
    super('check_in', translateService, alertController, loadingController, analyticsManager);
  }

  async ngOnInit() {
    const locationId: string = this.activatedRoute.snapshot.data.id;

    const location: Location | undefined = await this.locationManager.getLocation(locationId).pipe(first()).toPromise();

    if (location) {
      this.location = location;

      const checkIn: any | null = await this.session.getValue(CheckInPage.SESSION_CHECK_IN).pipe(first()).toPromise();
      if (checkIn) {
        await this.showCheckOutPrompt(checkIn.checkInId);
        await this.goBack();
        return;
      }

      this.setForm(this.createForm());

      await this.resetForm();
    } else {
      this.location = null;
    }
  }

  public setForm(dataForm: FormGroup): void {
    this.dataForm = dataForm;
  }

  public getForm(): FormGroup {
    return this.dataForm;
  }

  public createForm(): FormGroup {
    const formGroup: FormGroup = this.formBuilder.group({
      storeUserData: [true],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      companionNames: this.formBuilder.array([]),
      email: ['', Validators.email],
      phoneNumber: [''],
      address: [''],
      place: [''],
      accessRequirement: ['']
    });

    formGroup.get('email').disable();
    formGroup.get('phoneNumber').disable();
    formGroup.get('address').disable();
    if (this.location.checkInContactAny) {
      const options: string[] = this.location.checkInContactAny.split(',');
      if (options.includes('EMAIL')) {
        formGroup.get('email').enable();
      }
      if (options.includes('PHONE')) {
        formGroup.get('phoneNumber').enable();
      }
      if (options.includes('ADDRESS')) {
        formGroup.get('address').enable();
      }
    }
    if (this.location.checkInContactAll) {
      const options: string[] = this.location.checkInContactAll.split(',');
      if (options.includes('EMAIL')) {
        formGroup.get('email').setValidators([Validators.email, Validators.required]);
        formGroup.get('email').enable();
      }
      if (options.includes('PHONE')) {
        formGroup.get('phoneNumber').setValidators([Validators.required]);
        formGroup.get('phoneNumber').enable();
      }
      if (options.includes('ADDRESS')) {
        formGroup.get('address').setValidators([Validators.required]);
        formGroup.get('address').enable();
      }
    }
    formGroup.get('place').disable();
    if (this.location.checkInNecessityPlace === 'OPTIONAL') {
      formGroup.get('place').enable();
    } else if (this.location.checkInNecessityPlace === 'MANDATORY') {
      formGroup.get('place').setValidators([Validators.required]);
      formGroup.get('place').enable();
    }
    formGroup.get('accessRequirement').disable();
    if (this.location.checkInNecessityAccessRequirement === 'OPTIONAL') {
      formGroup.get('accessRequirement').enable();
    } else if (this.location.checkInNecessityAccessRequirement === 'MANDATORY') {
      formGroup.get('accessRequirement').setValidators([Validators.required]);
      formGroup.get('accessRequirement').enable();
    }

    return formGroup;
  }

  /**
   * Create a form group for a new person entry.
   */
  protected createNameEntry(): FormGroup {
    return this.formBuilder.group({
      name: ['']
    });
  }

  public async resetForm(): Promise<void> {
    this.currentCustomer = await this.session.getCurrentCustomer().pipe(first()).toPromise();
    if (this.currentCustomer === undefined) {
      const sessionCustomerData: any | null = await this.session.getValue('userData').pipe(first()).toPromise();

      if (sessionCustomerData !== null) {
        this.currentCustomer = new Customer();
        this.currentCustomer.marketingConsent = sessionCustomerData.marketingConsent ? sessionCustomerData.marketingConsent : false;
        if (sessionCustomerData.contact) {
          this.currentCustomer.firstname = sessionCustomerData.contact.firstname ? sessionCustomerData.contact.firstname : '';
          this.currentCustomer.lastname = sessionCustomerData.contact.lastname ? sessionCustomerData.contact.lastname : '';
          this.currentCustomer.email = sessionCustomerData.contact.email ? sessionCustomerData.contact.email : '';
          this.currentCustomer.phoneNumber = sessionCustomerData.contact.phoneNumber ? sessionCustomerData.contact.phoneNumber : '';
          this.currentCustomer.companyName = sessionCustomerData.contact.companyName ? sessionCustomerData.contact.companyName : '';
          this.currentCustomer.address = sessionCustomerData.contact.address ? sessionCustomerData.contact.address : null;
          this.currentCustomer.floor = sessionCustomerData.contact.floor ? sessionCustomerData.contact.floor : '';
        }
      }
    }

    const formData: any = {
      storeUserData: true,
      firstname: '',
      lastname: '',
      companionNames: [],
      email: '',
      phoneNumber: '',
      address: '',
      place: '',
      accessRequirement: ''
    };

    const locationPlaceId: string | null = await this.session.getValue('locationPlaceId').pipe(first()).toPromise();
    if (locationPlaceId !== null) {
      const place: LocationPlace | undefined = await this.locationManager.getLocationPlace(locationPlaceId).pipe(first()).toPromise();
      if (place) {
        formData.place = place.name;
      }
    }

    if (this.currentCustomer) {
      formData.firstname = this.currentCustomer.firstname ? this.currentCustomer.firstname : '';
      formData.lastname = this.currentCustomer.lastname ? this.currentCustomer.lastname : '';
      formData.email = this.currentCustomer.email ? this.currentCustomer.email : '';
      formData.phoneNumber = this.currentCustomer.phoneNumber ? this.currentCustomer.phoneNumber : '';
      formData.address = this.currentCustomer.address ? this.currentCustomer.address : '';
    }

    this.getForm().reset(formData);
    (this.getForm().get('companionNames') as FormArray).push(this.createNameEntry());
  }

  /**
   * Add a form group for a new person entry.
   */
  public addNameEntry(): void {
    (this.getForm().get('companionNames') as FormArray).push(this.createNameEntry());
    (this.getForm().get('companionNames') as FormArray).markAsDirty();
  }

  /**
   * Remove a form group from name entries.
   */
  public removeNameEntry(index: number): void {
    (this.getForm().get('companionNames') as FormArray).removeAt(index);
    (this.getForm().get('companionNames') as FormArray).markAsDirty();
  }

  public async presentAddressPrompt(initAddress: Address | undefined): Promise<void> {
    const modal = await this.modalController.create({
      component: AddressEditComponent,
      componentProps: {
        header: this.translateService.instant('CHECK_IN.ADDRESS_REQUEST_TITLE'),
        subHeader: this.translateService.instant('CHECK_IN.ADDRESS_REQUEST_TEXT'),
        initAddress,
        inputPlaceholder: this.translateService.instant('CHECK_IN.ADDRESS_REQUEST_PLACEHOLDER'),
        originLat: this.location.address ? this.location.address.lat : undefined,
        originLng: this.location.address ? this.location.address.lng : undefined
      }
    });

    await modal.present();

    const results = await modal.onDidDismiss();
    if (results.role === 'ok') {
      const address: Address = results.data.address;

      this.getForm().get(['address']).setValue(address);
      this.getForm().get(['address']).markAsDirty();
    }
  }

  public async clearAddress(event: MouseEvent): Promise<void> {
    event.preventDefault();
    this.getForm().get(['address']).setValue('');
  }

  public async saveForm(): Promise<void> {
    if (!this.getForm().valid) {
      this.showError('', this.translateService.instant('CHECK_IN.MANDATORY_ERROR'));
      return;
    }

    const checkIn: CheckIn = new CheckIn();
    checkIn.accountId = this.location.accountId;
    checkIn.locationId = this.location.id;
    checkIn.durationOfStay = this.location.checkInDurationOfStay;
    checkIn.durationOfStorage = this.location.checkInDurationOfStorage;
    checkIn.checkedInAt = new Date().toISOString();
    checkIn.firstname = this.getForm().value.firstname ? this.getForm().value.firstname.trim() : null;
    checkIn.lastname = this.getForm().value.lastname ? this.getForm().value.lastname.trim() : null;
    checkIn.email = this.getForm().value.email ? this.getForm().value.email.trim() : null;
    checkIn.phoneNumber = this.getForm().value.phoneNumber ? this.getForm().value.phoneNumber.trim() : null;
    checkIn.address = this.getForm().value.address ? this.getForm().value.address : null;
    checkIn.dineInPlaceName = this.getForm().value.place !== null && this.getForm().value.place !== undefined
      ? this.getForm().value.place : null;
    checkIn.accessRequirement = this.getForm().value.accessRequirement ? this.getForm().value.accessRequirement : null;

    checkIn.companionNames = [];
    (this.getForm().get('companionNames') as FormArray).controls.forEach((categoryFormGroup: FormGroup) => {
      const name: string | undefined = categoryFormGroup.value.name ? categoryFormGroup.value.name.trim() : undefined;
      if (name) {
        checkIn.companionNames.push(categoryFormGroup.value.name);
      }
    });

    if (this.location.checkInContactAny) {
      const options: string[] = this.location.checkInContactAny.split(',');
      let atLeastOne = false;
      for (const option of options) {
        if ((option === 'EMAIL' && checkIn.email) || (option === 'PHONE' && checkIn.phoneNumber)
          || (option === 'ADDRESS' && checkIn.address)) {
          atLeastOne = true;
          break;
        }
      }
      if (!atLeastOne) {
        const optionNames: string[] = options.map((option: string) => this.translateService.instant('CHECK_IN.' + option));
        this.showError('', this.translateService.instant('CHECK_IN.CONTACT_MIN_ERROR', { options: optionNames.join(', ')}));
        return;
      }
    }

    if (!this.currentCustomer && this.getForm().value.storeUserData) {
      let sessionCustomerData: any | null = await this.session.getValue('userData').pipe(first()).toPromise();

      if (sessionCustomerData === null) {
        sessionCustomerData = {};
        sessionCustomerData.createdAt = new Date().toISOString();
      }
      sessionCustomerData.updatedAt = new Date().toISOString();
      sessionCustomerData.storeUserData = true;

      if (!sessionCustomerData.contact) {
        sessionCustomerData.contact = {};
      }
      sessionCustomerData.contact.firstname = this.getForm().value.firstname;
      sessionCustomerData.contact.lastname = this.getForm().value.lastname;
      if (this.getForm().get(['email']).enabled) {
        sessionCustomerData.contact.email = this.getForm().value.email;
      }
      if (this.getForm().get(['phoneNumber']).enabled) {
        sessionCustomerData.contact.phoneNumber = this.getForm().value.phoneNumber;
      }
      if (this.getForm().get(['address']).enabled) {
        sessionCustomerData.contact.address = this.getForm().value.address;
      }

      await this.session.setValue('userData', sessionCustomerData, true);
    } else {
      await this.session.removeValue('userData');
    }

    this.logEvent('check_in', checkIn.id ? 'update' : 'add', checkIn.locationId, checkIn.id, this.location.id);
    await this.checkInManager.addCheckIn(checkIn);

    await this.session.setValue(
      CheckInPage.SESSION_CHECK_IN,
      {
        checkInId: checkIn.id,
        checkedInAt: checkIn.checkedInAt,
        durationOfStay: checkIn.durationOfStay
      },
      true
    );

    await this.showError(
      this.translateService.instant('CHECK_IN.CHECK_IN_CONFIRM_TITLE'),
      this.translateService.instant('CHECK_IN.CHECK_IN_CONFIRM_TEXT')
    );

    await this.goBack();
  }

  private async showCheckOutPrompt(checkInId: string): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translateService.instant(`CHECK_IN.CHECK_OUT_CONFIRM_TEXT`),
      buttons: [
        {
          text: this.translateService.instant('APP.CANCEL'),
          role: 'cancel',
          handler: async () => {
            await this.alertController.dismiss();
          }
        }, {
          text: this.translateService.instant('APP.OK'),
          handler: async () => {
            await this.showLoading();

            await this.checkInManager.checkOutGuest(checkInId);
            await this.session.removeValue(CheckInPage.SESSION_CHECK_IN);

            await this.hideLoading();
          }
        }
      ]
    });

    await alert.present();
  }

  public async goBack(): Promise<void> {
    await this.navController.navigateBack(`/l/${this.location.linkName}`);
  }
}
