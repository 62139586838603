import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsManager } from './manager/analytics-manager.service';


/**
 * Base class for all pages.
 */
export abstract class BasePage {

  private loadingElement: HTMLIonLoadingElement | null = null;

  private pageValue?: string;

  /**
   * The default constructor.
   */
  protected constructor(
    protected pageLabel: string,
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager
  ) {
  }

  ionViewDidEnter() {
    this.logPageView(this.pageLabel);
  }

  protected logPageView(pageLabel: string, pageValue?: string, locationId?: string) {
    this.pageLabel = pageLabel;
    this.pageValue = pageValue;
    this.analyticsManager.logPageView(pageLabel, pageValue, document.URL, locationId).then();
  }

  protected logEvent(category: string, action: string, label?: string, value?: string, locationId?: string, weight?: number) {
    this.analyticsManager.logEvent(this.pageLabel, this.pageValue, category, action, label, value, locationId, weight).then();
  }

  /**
   * Shows a custom error message.
   * @param title: Title of custom error-message
   * @param text: Text of custom error-message
   */
  public async showError(title: string, text: string): Promise<void> {
    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [this.translateService.instant('APP.OK')]
    });

    await alert.present();
  }

  public async showLoading(): Promise<void> {
    if (this.loadingElement === null) {
      this.loadingElement = await this.loadingController.create();
      await this.loadingElement.present();
    }
  }

  public async hideLoading(): Promise<void> {
    if (this.loadingElement !== null) {
      await this.loadingElement.dismiss();
      this.loadingElement = null;
    }
  }
}
