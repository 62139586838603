import * as voucherCode from 'voucher-code-generator';


/**
 * Utility class for code generation.
 */
export class CodeUtils {

  /**
   * Creates a uid.
   */
  static generateUid(): string {
    const codes: string[] = voucherCode.generate({
      length: 30,
      count: 1,
      charset: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    });

    return codes[0];
  }
}
