import { Pipe, PipeTransform } from '@angular/core';

import { LanguageDictionary } from '../models/language-dictionary';
import { DictUtils } from '../utils/dict-utils';


@Pipe({
  name: 'langTranslate'
})
export class TranslationPipe implements PipeTransform {

  transform(key: string, args: {strings?: LanguageDictionary[], defaultLang?: string, currentLang?: string, optional?: boolean}): string {
    let translatedText: string | null | undefined = DictUtils.getString(args.strings, key, args.currentLang, true);

    if (translatedText === undefined || translatedText === null) {
      translatedText = DictUtils.getString(args.strings, key, args.defaultLang, true);
    }

    if (translatedText === null) {
      translatedText = '';
    }

    if (translatedText === undefined && args.optional !== true) {
      translatedText = key;
    }

    return translatedText;
  }
}
