import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Order } from '../models/order';
import { OrderReceipt } from '../models/order-receipt';
import { DatabaseAccess } from '../access/database-access.service';


/**
 * Class providing management methods for orders.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderManager {

  /**
   * The default constructor.
   */
  constructor(private databaseAccess: DatabaseAccess) {
  }

  /**
   * Returns all Orders of an Event.
   *
   * @param eventId the ID of the Event
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrders(eventId: string): Observable<Order[]> {
    return this.databaseAccess.getAllOrdersOfEvent(eventId);
  }

  /**
   * Returns all Orders of a Customer.
   *
   * @param customerId the ID of the Customer
   * @returns the found Orders, otherwise empty list
   */
  public getOrdersOfCustomer(customerId: string): Observable<Order[]> {
    return this.databaseAccess.getOrdersOfCustomer(customerId);
  }

  /**
   * Returns an Order specified by the ID.
   *
   * @param orderId the Order ID
   * @returns the found Order, otherwise undefined
   */
  public getOrder(orderId: string): Observable<Order | undefined> {
    return this.databaseAccess.getOrder(orderId);
  }

  /**
   * Adds an order to the specified event.
   *
   * @param order the order to add
   * @param userId the user ID
   * @param eventId the event ID
   * @returns the added order
   */
  public async addOrder(order: Order, userId?: string, eventId?: string): Promise<string> {
    if (userId) {
      order.userId = userId;
    }

    return this.databaseAccess.addOrder(order, eventId);
  }

  /**
   * Updates the order of the specified event.
   *
   * @param order the order to update
   * @param eventId the event ID
   * @returns the updated order
   */
  public async updateOrder(order: Order, eventId?: string): Promise<string> {
    return this.databaseAccess.updateOrder(order, eventId);
  }

  /**
   * Removes an order.
   *
   * @param eventId the event ID
   * @param orderId the order ID
   */
  public async removeOrder(eventId: string, orderId: string): Promise<void> {
    return this.databaseAccess.removeOrder(eventId, orderId);
  }

  /**
   * Returns an OrderReceipt specified by the ID.
   *
   * @param orderReceiptId the OrderReceipt ID
   * @returns the found OrderReceipt, otherwise undefined
   */
  public getOrderReceipt(orderReceiptId: string): Observable<OrderReceipt> {
    return this.databaseAccess.getOrderReceipt(orderReceiptId);
  }

  /**
   * Add new OrderReceipt to cloud.
   *
   * @param orderReceipt the OrderReceipt object to add
   * @return the id of the new OrderReceipt
   */
  public async addOrderReceipt(orderReceipt: OrderReceipt): Promise<string> {
    return this.databaseAccess.addOrderReceipt(orderReceipt);
  }

  /**
   * Removes OrderReceipts.
   *
   * @param orderReceiptIds the IDs of the OrderReceipts to remove
   */
  public async removeOrderReceipts(orderReceiptIds: string[]): Promise<void> {
    return this.databaseAccess.removeOrderReceipts(orderReceiptIds);
  }
}
