import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Location } from '../models/location';
import { LocationImage } from '../models/location-image';
import { LocationPlace } from '../models/location-place';


/**
 * Class providing access methods for locations.
 */
@Injectable({
  providedIn: 'root'
})
export class LocationAccess {

  // set the chunk size used for the query operator 'in'
  private readonly CHUNK_SIZE = 10;

  private locationCollection: AngularFirestoreCollection<Location>;
  private locationImageCollection: AngularFirestoreCollection<LocationImage>;
  private locationPlaceCollection: AngularFirestoreCollection<LocationPlace>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
  ) {
    this.locationCollection = afs.collection<Location>('locations');
    this.locationImageCollection = afs.collection<LocationImage>('locationImages');
    this.locationPlaceCollection = afs.collection<LocationPlace>('locationPlaces');
  }

  /**
   * Add new Location to cloud.
   *
   * @param location the Location object to add
   * @return the id of the new Location
   */
  public async addLocation(location: Location): Promise<string> {
    if (!location.id) {
      location.id = this.afs.createId();
      location.createdAt = new Date().toISOString();
    }
    location.updatedAt = new Date().toISOString();

    await this.locationCollection.doc(location.id).set(classToPlain(location) as Location, { merge: true });
//    } else {
//      await this.locationCollection.add(classToPlain(location) as Location);
//    }

    return location.id;
  }

  /**
   * Returns all locations.
   *
   * @returns the found locations, otherwise empty list
   */
  public getAllLocations(): Observable<Location[]> {
    return this.afs.collection<Location>('locations', ref => ref.orderBy('name'))
      .valueChanges().pipe(
        map((locationsJson) => {
          return locationsJson as Location[]; // plainToClass(Location, locationsJson as object[]);
        })
      );
  }

  /**
   * Returns all locations of an account.
   *
   * @param accountId the ID of the account
   * @returns the found locations, otherwise empty list
   */
  public getAllLocationsOfAccount(accountId: string): Observable<Location[]> {
    return this.afs.collection<Location>('locations', ref => ref.where('accountId', '==', accountId).orderBy('name'))
      .valueChanges().pipe(
        map((locationsJson) => {
          return locationsJson as Location[]; // plainToClass(Location, locationsJson as object[]);
        })
      );
  }

  /**
   * Returns the Locations specified by the IDs.
   *
   * @param locationIds the Location IDs
   * @returns the found locations, otherwise empty list
   */
  public getLocations(locationIds: string[]): Observable<Location[]> {
    const observables: Observable<Location[]>[] = [];

    for (let index = 0; index < locationIds.length; index += this.CHUNK_SIZE) {
      const chunk: string[] = locationIds.slice(index, index + this.CHUNK_SIZE);

      observables.push(this.afs.collection<Location>('locations', ref => ref.where('id', 'in', chunk))
        .valueChanges().pipe(
          map((locationsJson) => {
            return locationsJson as Location[]; // plainToClass(Location, locationsJson as object[]);
          })
        ));
    }

    return combineLatest(observables).pipe(
      map((locationsArray: Location[][]) => {
        const locations: Location[] = [];
        for (const locationsElement of locationsArray) {
          locations.push(...locationsElement);
        }
        return locations;
      })
    );
  }

  /**
   * Returns the Location specified by the ID.
   *
   * @param locationId the Location ID
   * @returns the found location, otherwise undefined
   */
  public getLocation(locationId: string): Observable<Location | undefined> {
    return this.locationCollection.doc(locationId).valueChanges().pipe(
      map((locationJson) => {
        return locationJson as Location; // plainToClass(Location, locationJson);
      })
    );
  }

  /**
   * Returns the Location specified by the link name.
   *
   * @param locationLinkName the Location link name
   * @returns the found location, otherwise undefined
   */
  public getLocationByLinkName(locationLinkName: string): Observable<Location | undefined> {
    return this.afs.collection<Location>('locations', ref => ref.where('linkName', '==', locationLinkName))
      .valueChanges().pipe(
        map((locationsJson) => {
          return locationsJson as Location[]; // plainToClass(Location, locationsJson as object[]);
        }),
        map((locations: Location[]) => locations.length > 0 ? locations[0] : undefined)
      );
  }

  /**
   * Removes Locations.
   *
   * @param locationIds the IDs of the Locations to remove
   */
  public async removeLocations(locationIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    const imageIdsToRemove: string[] = [];
    const placeIdsToRemove: string[] = [];
    for (const locationId of locationIds) {
      const location: Location | undefined = await this.getLocation(locationId).pipe(first()).toPromise();
      if (location && location.imageIds) {
        imageIdsToRemove.push(...location.imageIds);
      }
      const places: LocationPlace[] = await this.getAllLocationPlaces(locationId).pipe(first()).toPromise();
      placeIdsToRemove.push(...places.map((place: LocationPlace) => place.id));

      batch.delete(this.locationCollection.doc(locationId).ref);
    }
    await this.removeLocationImages(imageIdsToRemove);
    await this.removeLocationPlaces(placeIdsToRemove);

    await batch.commit();
  }

  /**
   * Changes visibility of Locations.
   *
   * @param locationIds the IDs of the Locations to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfLocations(locationIds: string[], visible: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const locationId of locationIds) {
      batch.update(this.locationCollection.doc(locationId).ref, { visibility: visible });
    }

    await batch.commit();
  }

  /**
   * Add new LocationImage to cloud.
   *
   * @param locationImage the LocationImage object to add
   * @return the id of the new LocationImage
   */
  public async addLocationImage(locationImage: LocationImage): Promise<string> {
    if (!locationImage.id) {
      locationImage.id = this.afs.createId();
      locationImage.createdAt = new Date().toISOString();
    }
    locationImage.updatedAt = new Date().toISOString();

    await this.locationImageCollection.doc(locationImage.id).set(classToPlain(locationImage) as LocationImage, { merge: true });

    return locationImage.id;
  }

  /**
   * Returns the LocationImage specified by the ID.
   *
   * @param locationImageId the LocationImage ID
   * @returns the found locationImage, otherwise undefined
   */
  public getLocationImage(locationImageId: string): Observable<LocationImage> {
    return this.locationImageCollection.doc(locationImageId).valueChanges().pipe(
      map((locationImageJson) => {
        return locationImageJson as LocationImage; // plainToClass(LocationImage, locationImageJson);
      })
    );
  }

  /**
   * Removes LocationImages.
   *
   * @param locationImageIds the IDs of the LocationImages to remove
   */
  public async removeLocationImages(locationImageIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const locationImageId of locationImageIds) {
      batch.delete(this.locationImageCollection.doc(locationImageId).ref);
    }

    await batch.commit();
  }

  /**
   * Add new LocationPlace to cloud.
   *
   * @param locationPlace the LocationPlace object to add
   * @return the id of the new LocationPlace
   */
  public async addLocationPlace(locationPlace: LocationPlace): Promise<string> {
    if (!locationPlace.id) {
      locationPlace.id = this.afs.createId();
      locationPlace.createdAt = new Date().toISOString();
    }
    locationPlace.updatedAt = new Date().toISOString();

    await this.locationPlaceCollection.doc(locationPlace.id).set(classToPlain(locationPlace) as LocationPlace, { merge: true });

    return locationPlace.id;
  }

  /**
   * Returns all LocationPlaces of an account.
   *
   * @param accountId the ID of the account
   * @returns the found LocationPlaces, otherwise empty list
   */
  public getAllLocationPlacesOfAccount(accountId: string): Observable<LocationPlace[]> {
    return this.afs.collection<LocationPlace>('locationPlaces', ref => ref.where('accountId', '==', accountId).orderBy('name'))
      .valueChanges().pipe(
        map((locationPlacesJson) => {
          return locationPlacesJson as LocationPlace[]; // plainToClass(LocationPlace, locationPlacesJson as object[]);
        })
      );
  }

  /**
   * Returns all LocationPlaces of a location.
   *
   * @param locationId the ID of the location
   * @returns the found LocationPlaces, otherwise empty list
   */
  public getAllLocationPlaces(locationId: string): Observable<LocationPlace[]> {
    return this.afs.collection<LocationPlace>('locationPlaces', ref => ref.where('locationId', '==', locationId).orderBy('name'))
      .valueChanges().pipe(
        map((locationPlacesJson) => {
          return locationPlacesJson as LocationPlace[]; // plainToClass(LocationPlace, locationPlacesJson as object[]);
        })
      );
  }

  /**
   * Returns the LocationPlace specified by the ID.
   *
   * @param locationPlaceId the LocationPlace ID
   * @returns the found LocationPlace, otherwise undefined
   */
  public getLocationPlace(locationPlaceId: string): Observable<LocationPlace> {
    return this.locationPlaceCollection.doc(locationPlaceId).valueChanges().pipe(
      map((locationPlaceJson) => {
        return locationPlaceJson as LocationPlace; // plainToClass(LocationPlace, locationPlaceJson);
      })
    );
  }

  /**
   * Removes LocationPlaces.
   *
   * @param locationPlaceIds the IDs of the LocationPlaces to remove
   */
  public async removeLocationPlaces(locationPlaceIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const locationPlaceId of locationPlaceIds) {
      batch.delete(this.locationPlaceCollection.doc(locationPlaceId).ref);
    }

    await batch.commit();
  }
}
