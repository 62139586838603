import { DeliveryRule, Location } from '../models/location';
import { CatalogMenu } from '../models/catalog-menu';
import { CatalogCategory } from '../models/catalog-category';
import { CatalogItem, CatalogTaxOverride } from '../models/catalog-item';
import { CatalogItemVariation } from '../models/catalog-item-variation';
import { CatalogModifier } from '../models/catalog-modifier';
import { CatalogModifierList } from '../models/catalog-modifier-list';
import { CatalogDiscount } from '../models/catalog-discount';
import { CatalogDiscountCode } from '../models/catalog-discount-code';
import { CatalogPricingRule } from '../models/catalog-pricing-rule';
import { CatalogTax } from '../models/catalog-tax';
import { CatalogDiet } from '../models/catalog-diet';
import { Order, OrderLineItemDiscount, OrderLineItemDiscountScopeConstants, OrderLineItemTax } from '../models/order';
import { OrderLineItem, OrderLineItemAppliedDiscount, OrderLineItemAppliedTax, OrderLineItemModifier } from '../models/order-line-item';
import { Money } from '../models/money';
import { DictUtils } from '../utils/dict-utils';
import { LocationPriceUtils } from '../utils/location-price-utils';
import { MoneyUtils } from '../utils/money-utils';
import { CodeUtils } from '../utils/code-utils';

/**
 * The class represents a shopping basket with necessary functionality.
 */
export class Basket {

  // Look-up-tables
  public catalogMenuMap: Map<string, CatalogMenu> = new Map();
  public catalogCategoryMap: Map<string, CatalogCategory> = new Map();
  public catalogCategoryMenuMap: Map<string, CatalogMenu> = new Map();
  public catalogItemMap: Map<string, CatalogItem> = new Map();
  public catalogItemVariationMap: Map<string, CatalogItemVariation> = new Map();
  public catalogModifierMap: Map<string, CatalogModifier> = new Map();
  public catalogDiscountMap: Map<string, CatalogDiscount> = new Map();
  public catalogPricingRuleMap: Map<string, CatalogPricingRule> = new Map();
  public catalogTaxMap: Map<string, CatalogTax> = new Map();

  public catalogDiets: CatalogDiet[] = [];
  public supportedCatalogDiets: string[] = [];
  public selectedCatalogDietIds: string[] = [];

  private location: Location;
  private order: Order;

  public totalItems = 0;
  public variationQuantityMap: Map<string, number> = new Map();

  public deliveryRule?: DeliveryRule;
  public discountCode?: CatalogDiscountCode;
  public tip: Money = new Money();
  public tipOptions: { text: string, tip: Money }[] = [];

  // properties specific to the selected orderingType
  public orderingType: string;
  public typeTotalItems = 0;
  public typeSubtotal: Money = new Money();
  public typeTotal: Money = new Money();
  public typeDiscounts: OrderLineItemDiscount[] = [];
  public typeTotalTip: Money = new Money();
  public typeTaxes: OrderLineItemTax[] = [];
  public typeLineItems: OrderLineItem[] = [];

  private collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

  constructor() {
  }

  public setLocation(location: Location): void {
    this.location = location;

    if (!this.location.orderingDeliveryRules || this.location.orderingDeliveryRules.length === 0) {
      // legacy
      if (this.location.orderingDeliveryArea && this.location.orderingDeliveryArea !== 'DEPRECATED') {
        this.deliveryRule = new DeliveryRule();
        this.deliveryRule.triggerType = 'POSTAL_CODE';
        this.deliveryRule.postalCodes = location.orderingDeliveryArea;
        this.deliveryRule.minOrderValue = new Money();
        this.deliveryRule.minOrderValue.amount = location.orderingDeliveryMinOrderValue.amount;
        this.deliveryRule.minOrderValue.currency = location.orderingDeliveryMinOrderValue.currency;
        this.deliveryRule.shipping = new Money();
        this.deliveryRule.shipping.amount = location.orderingDeliveryShipping.amount;
        this.deliveryRule.shipping.currency = location.orderingDeliveryShipping.currency;
      } else {
        this.deliveryRule = undefined;
      }
    }

    this.supportedCatalogDiets = location.orderingSupportedDiets ? location.orderingSupportedDiets.split(',') : [];

    this.updateOrderMeta();
  }

  public setOrderingType(orderingType: string): void {
    this.orderingType = orderingType;
    this.updateOrderMeta();
  }

  public setCatalogObjects(menus: CatalogMenu[], categoryItemMap: Map<string, CatalogItem[]>,
                           modifierMap: Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }>,
                           catalogDiscounts: CatalogDiscount[], catalogPricingRules: CatalogPricingRule[],
                           catalogTaxes: CatalogTax[], dietMap: Map<string, CatalogDiet>): void {
    this.catalogMenuMap.clear();
    this.catalogCategoryMap.clear();
    this.catalogItemMap.clear();
    this.catalogItemVariationMap.clear();
    this.catalogModifierMap.clear();
    this.catalogDiscountMap.clear();
    this.catalogPricingRuleMap.clear();
    this.catalogTaxMap.clear();
    this.catalogDiets.length = 0;

    menus.forEach((menu: CatalogMenu) => {
      this.catalogMenuMap.set(menu.id, menu);
      if (menu.categories) {
        menu.categories.forEach((category: CatalogCategory) => {
          this.catalogCategoryMap.set(category.id, category);
          this.catalogCategoryMenuMap.set(category.id, menu);
        });
      }
    });
    categoryItemMap.forEach((items: CatalogItem[]) => {
      items.forEach((item: CatalogItem) => {
        this.catalogItemMap.set(item.id, item);
        item.variations.forEach((variation: CatalogItemVariation) => this.catalogItemVariationMap.set(variation.id, variation));
      });
    });
    modifierMap.forEach((modifierData: { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }) => {
      modifierData.modifiers.forEach((modifier: CatalogModifier) => this.catalogModifierMap.set(modifier.id, modifier));
    });

    catalogDiscounts.forEach((catalogDiscount: CatalogDiscount) => this.catalogDiscountMap.set(catalogDiscount.id, catalogDiscount));
    catalogPricingRules.forEach((catalogPricingRule: CatalogPricingRule) => this.catalogPricingRuleMap.set(catalogPricingRule.id, catalogPricingRule));

    catalogTaxes.forEach((catalogTax: CatalogTax) => this.catalogTaxMap.set(catalogTax.id, catalogTax));

    this.catalogDiets.push(...Array.from(dietMap.values()).sort((a: CatalogDiet, b: CatalogDiet) => this.collator.compare(a.abbreviation, b.abbreviation)));

    this.updateOrderMeta();
  }

  public setDeliveryRule(rule: DeliveryRule | undefined): void {
    this.deliveryRule = rule;
    this.updateOrderMeta();
  }

  public setDiscountCode(code: CatalogDiscountCode | undefined): void {
    this.discountCode = code;
    this.updateOrderMeta();
  }

  public setTip(tip: Money): void {
    this.tip = tip;
    this.updateOrderMeta();
  }

  public resetTip(): void {
    this.tip = new Money();
    this.updateOrderMeta();
  }

  public setOrder(order: Order): void {
    this.order = order;

    // sort lineItems by CatalogItem ordinal
    this.order.lineItems = this.order.lineItems.sort((a: OrderLineItem, b: OrderLineItem) => {
      const ordinalA = this.catalogItemMap.has(a.itemId) && this.catalogItemMap.get(a.itemId).ordinal ? this.catalogItemMap.get(a.itemId).ordinal : 0;
      const ordinalB = this.catalogItemMap.has(b.itemId) && this.catalogItemMap.get(b.itemId).ordinal ? this.catalogItemMap.get(b.itemId).ordinal : 0;
      return ordinalA - ordinalB;
    });

    this.updateOrderMeta();
  }

  public getOrder(): Order {
    return this.order;
  }

  public exportOrder(): Order {
    const newOrder: Order = JSON.parse(JSON.stringify(this.order)) as Order;
    newOrder.lineItems = JSON.parse(JSON.stringify(this.typeLineItems)) as OrderLineItem[];
    newOrder.discounts = JSON.parse(JSON.stringify(this.typeDiscounts)) as OrderLineItemDiscount[];
    newOrder.taxes = JSON.parse(JSON.stringify(this.typeTaxes)) as OrderLineItemTax[];
    newOrder.totalTipMoney = JSON.parse(JSON.stringify(this.typeTotalTip)) as Money;
    newOrder.subtotalMoney = JSON.parse(JSON.stringify(this.typeSubtotal)) as Money;
    newOrder.totalMoney = JSON.parse(JSON.stringify(this.typeTotal)) as Money;

    return newOrder;
  }

  // TODO should be moved to server
  private updateOrderMeta(): void {
    if (this.location && this.order) {
      this.updateLineItemData(this.order.lineItems, this.catalogCategoryMap, this.catalogCategoryMenuMap,
        this.catalogItemMap, this.catalogItemVariationMap, this.catalogModifierMap, this.location);
      this.updateDiscountsInOrder(this.order, this.catalogDiscountMap, this.catalogPricingRuleMap, this.catalogCategoryMenuMap, this.catalogItemMap);
      this.updateItemLevelTaxes(this.order, this.catalogTaxMap, this.catalogItemMap);
    }
    this.updateTotal(this.orderingType === 'SHIPMENT' && this.deliveryRule ? this.deliveryRule.shipping : undefined);
  }

  private updateLineItemData(orderLineItems: OrderLineItem[],
                             catalogCategoryMap: Map<string, CatalogCategory>, catalogCategoryMenuMap: Map<string, CatalogMenu>,
                             catalogItemMap: Map<string, CatalogItem>, catalogItemVariationMap: Map<string, CatalogItemVariation>,
                             catalogModifierMap: Map<string, CatalogModifier>,
                             location: Location): void {
    this.typeLineItems.length = 0;

    orderLineItems.forEach((lineItem: OrderLineItem) => {
      const item: CatalogItem | undefined = catalogItemMap.get(lineItem.itemId);
      const variation: CatalogItemVariation | undefined = catalogItemVariationMap.get(lineItem.itemVariationId);

      // update item and variation data in OrderLineItem
      if (item && item.visibility !== 'hidden' && variation) {
        const category: CatalogCategory | undefined = catalogCategoryMap.get(item.categoryId);
        const menu: CatalogMenu | undefined = catalogCategoryMenuMap.get(item.categoryId);

        if (menu && menu.visibility !== 'hidden' && category && category.visibility !== 'hidden') {
          lineItem.name = item.name;
          const variationName: string | null | undefined = DictUtils.getString(item.strings, variation.name, item.defaultLang);
          lineItem.variationName = variationName ? variationName : '';
          lineItem.priceMoney = LocationPriceUtils.getPrice(variation, location.id);  // TODO DEPRECATED
          lineItem.basePriceMoney = LocationPriceUtils.getPrice(variation, location.id);

          if (lineItem.basePriceMoney) {
            lineItem.variationTotalPriceMoney = MoneyUtils.multScalar(lineItem.quantity, lineItem.basePriceMoney);

            lineItem.modifierTotalPriceMoney = new Money();
            if (lineItem.modifiers) {
              const newModifiers: OrderLineItemModifier[] = [];
              lineItem.modifiers.forEach((lineItemModifier: OrderLineItemModifier) => {
                const catalogModifier: CatalogModifier | undefined = catalogModifierMap.get(lineItemModifier.modifierId);
                if (catalogModifier) {
                  lineItemModifier.name = DictUtils.getString(catalogModifier.strings, catalogModifier.name, catalogModifier.defaultLang);
                  lineItemModifier.basePriceMoney = catalogModifier.priceMoney;
                  lineItemModifier.totalPriceMoney = MoneyUtils.multScalar(lineItem.quantity, lineItemModifier.basePriceMoney);
                  newModifiers.push(lineItemModifier);

                  lineItem.modifierTotalPriceMoney = MoneyUtils.add(lineItemModifier.totalPriceMoney, lineItem.modifierTotalPriceMoney);
                }
              });
              lineItem.modifiers = newModifiers;
            }

            lineItem.grossSalesMoney = MoneyUtils.add(lineItem.variationTotalPriceMoney, lineItem.modifierTotalPriceMoney);
            lineItem.totalMoney = MoneyUtils.multScalar(1, lineItem.grossSalesMoney);

            if (this.orderingType === 'DINE_IN' && item.availableForDineIn !== false) {
              this.typeLineItems.push(lineItem);
            } else if (this.orderingType === 'PICKUP' && item.availableForPickup !== false) {
              this.typeLineItems.push(lineItem);
            } else if (this.orderingType === 'SHIPMENT' && item.availableForDelivery !== false) {
              this.typeLineItems.push(lineItem);
            }
          }
        }
      }
    });
  }

  /**
   * Determines the discounts.
   *
   * @param order the order
   * @param catalogDiscountMap the map with CatalogDiscounts
   * @param catalogPricingRuleMap the map with CatalogPricingRules
   * @param catalogCategoryMenuMap the map with CatalogCategoryId and corresponding CatalogMenus
   * @param catalogItemMap the map with CatalogItems
   */
  private updateDiscountsInOrder(order: Order, catalogDiscountMap: Map<string, CatalogDiscount>,
                                 catalogPricingRuleMap: Map<string, CatalogPricingRule>, catalogCategoryMenuMap: Map<string, CatalogMenu>,
                                 catalogItemMap: Map<string, CatalogItem>): void {
    // clear all discounts
    this.typeDiscounts = [];
    if (order.lineItems) {
      order.lineItems.forEach((lineItem: OrderLineItem) => {
        lineItem.appliedDiscounts = [];
        lineItem.totalDiscountMoney = new Money();
      });
    }

    if (this.typeLineItems) {
      let subtotal = new Money();
      this.typeLineItems.forEach((lineItem: OrderLineItem) => subtotal = MoneyUtils.add(lineItem.grossSalesMoney, subtotal));

      catalogPricingRuleMap.forEach((rule: CatalogPricingRule) => {
        const lineItemDiscount: OrderLineItemDiscount | undefined
          = this.checkRuleAndApplyDiscount(this.typeLineItems, rule, subtotal, this.discountCode, catalogDiscountMap, catalogCategoryMenuMap, catalogItemMap);
        if (lineItemDiscount) {
          this.typeDiscounts.push(lineItemDiscount);
        }
      });

      // update all line item totals
      this.typeLineItems.forEach((lineItem: OrderLineItem) => {
        lineItem.totalDiscountMoney = new Money();

        if (lineItem.appliedDiscounts) {
          for (const discount of lineItem.appliedDiscounts) {
            lineItem.totalDiscountMoney = MoneyUtils.add(discount.appliedMoney, lineItem.totalDiscountMoney);
          }
        }

        lineItem.totalMoney = lineItem.grossSalesMoney
          ? MoneyUtils.subtract(lineItem.grossSalesMoney, lineItem.totalDiscountMoney)
          : MoneyUtils.multScalar(-1, lineItem.totalDiscountMoney);
      });
    }
  }

  private checkRuleAndApplyDiscount(lineItems: OrderLineItem[], rule: CatalogPricingRule, subtotal: Money,
                                    discountCode: CatalogDiscountCode,
                                    catalogDiscountMap: Map<string, CatalogDiscount>,
                                    catalogCategoryMenuMap: Map<string, CatalogMenu>,
                                    catalogItemMap: Map<string, CatalogItem>): OrderLineItemDiscount | undefined {
    let orderLineItemDiscount: OrderLineItemDiscount;

    // checks whether to apply this rule
    if ((rule.triggerType === 'AUTO' || (rule.triggerType === 'CODE' && discountCode && rule.id === discountCode.pricingRuleId))
      && rule.matchFulfillmentTypes && rule.matchFulfillmentTypes.split(',').includes(this.orderingType)
      && (!rule.prerequisiteSubtotalMinMoney || rule.prerequisiteSubtotalMinMoney.amount < subtotal.amount)
      && catalogDiscountMap.has(rule.discountId)) {

      const discount: CatalogDiscount = catalogDiscountMap.get(rule.discountId);

      orderLineItemDiscount = new OrderLineItemDiscount();
      orderLineItemDiscount.uid = CodeUtils.generateUid();
      orderLineItemDiscount.catalogDiscountId = discount.id;
      orderLineItemDiscount.name = discount.name;
      orderLineItemDiscount.scope = OrderLineItemDiscountScopeConstants.ITEM;  // until now there is only ITEM as scope
      orderLineItemDiscount.type = discount.discountType;
      if (discount.discountType === 'FIXED_PERCENTAGE' && discount.percentage !== undefined && discount.percentage !== null) {
        orderLineItemDiscount.percentage = discount.percentage;
      } else if (discount.discountType === 'FIXED_AMOUNT' && discount.amountMoney !== undefined && discount.amountMoney !== null) {
        orderLineItemDiscount.amountMoney = discount.amountMoney;
      }
      orderLineItemDiscount.appliedMoney = new Money();

      // applies discount depending on scope
      if (orderLineItemDiscount.scope === OrderLineItemDiscountScopeConstants.ITEM) {
        // finds line items the discount is applied to
        const foundLineItems: OrderLineItem[] = [];
        if (rule.matchProducts) {
          if (rule.matchProducts.allProducts) {
            foundLineItems.push(...lineItems);
          } else if (rule.matchProducts.productIdsAny) {
            this.typeLineItems.forEach((lineItem: OrderLineItem) => {
              const catalogItem: CatalogItem | undefined = catalogItemMap.get(lineItem.itemId);
              const catalogMenu: CatalogMenu | undefined = catalogItem ? catalogCategoryMenuMap.get(catalogItem.categoryId) : undefined;

              if (rule.matchProducts.productIdsAny.includes(lineItem.itemId)
                || (catalogItem && rule.matchProducts.productIdsAny.includes(catalogItem.categoryId))
                || (catalogMenu && rule.matchProducts.productIdsAny.includes(catalogMenu.id))) {
                foundLineItems.push(lineItem);
              }
            });
          }
        }

        // sorts lineItems by exclude-strategy
        foundLineItems.sort((a: OrderLineItem, b: OrderLineItem) =>
          rule.excludeStrategy === 'LEAST_EXPENSIVE'
            ? (a.grossSalesMoney.amount / a.quantity) - (b.grossSalesMoney.amount / b.quantity)
            : (b.grossSalesMoney.amount / b.quantity) - (a.grossSalesMoney.amount / a.quantity));

        // some settings for the discount
        let quantityMax: number | undefined =
          rule.matchProducts && rule.matchProducts.quantityMax !== undefined && rule.matchProducts.quantityMax !== null
            ? rule.matchProducts.quantityMax
            : undefined;

        // creates all discounts applied to the line items
        const lineItemAppliedDiscountMap: Map<OrderLineItem, OrderLineItemAppliedDiscount> = new Map();
        let totalAppliedMoney: Money = new Money();

        for (const lineItem of foundLineItems) {
          const appliedMoney: Money = this.calcDiscountAmountPerLineItem(discount, lineItem, quantityMax);

          const orderLineItemAppliedDiscount: OrderLineItemAppliedDiscount = new OrderLineItemAppliedDiscount();
          orderLineItemAppliedDiscount.discountUid = orderLineItemDiscount.uid;
          orderLineItemAppliedDiscount.appliedMoney = appliedMoney;
          lineItemAppliedDiscountMap.set(lineItem, orderLineItemAppliedDiscount);
          totalAppliedMoney = MoneyUtils.add(totalAppliedMoney, appliedMoney);

          if (quantityMax !== undefined) {
            quantityMax -= lineItem.quantity;
            if (quantityMax <= 0) {
              break;
            }
          }
        }

        // checks max discount amount and reduces if necessary
        if (discount.amountMoneyMax && totalAppliedMoney.amount > discount.amountMoneyMax.amount) {
          const diff: Money = MoneyUtils.subtract(totalAppliedMoney, discount.amountMoneyMax);
          this.distributeReduction(lineItemAppliedDiscountMap, diff);
        }

        // adds discounts to related line items
        lineItemAppliedDiscountMap.forEach((orderLineItemAppliedDiscount: OrderLineItemAppliedDiscount, lineItem: OrderLineItem) => {
          if (!lineItem.appliedDiscounts) {
            lineItem.appliedDiscounts = [];
          }
          lineItem.appliedDiscounts.push(orderLineItemAppliedDiscount);
          orderLineItemDiscount.appliedMoney = MoneyUtils.add(orderLineItemDiscount.appliedMoney, orderLineItemAppliedDiscount.appliedMoney);
        });
      } else if (orderLineItemDiscount.scope === OrderLineItemDiscountScopeConstants.ORDER) {
        orderLineItemDiscount.appliedMoney = this.calcDiscountAmount(discount, subtotal);
      }
    }

    return orderLineItemDiscount;
  }

  private distributeReduction(lineItemAppliedDiscountMap: Map<OrderLineItem, OrderLineItemAppliedDiscount>, reductionMoney: Money): Money {
    let totalMoney: Money = new Money();
    lineItemAppliedDiscountMap.forEach((discount: OrderLineItemAppliedDiscount) => totalMoney = MoneyUtils.add(totalMoney, discount.appliedMoney));

    let remainReductionMoney: Money = reductionMoney;

    if (totalMoney.amount > 0) {
      const ratio: number = remainReductionMoney.amount / totalMoney.amount;

      let index = 0;
      lineItemAppliedDiscountMap.forEach((orderLineItemAppliedDiscount: OrderLineItemAppliedDiscount) => {
        let reduction: Money = index === lineItemAppliedDiscountMap.size - 1
          ? remainReductionMoney
          : MoneyUtils.multScalar(ratio, orderLineItemAppliedDiscount.appliedMoney);
        if (reduction.amount > orderLineItemAppliedDiscount.appliedMoney.amount) {
          reduction = orderLineItemAppliedDiscount.appliedMoney;
        }
        if (reduction.amount > remainReductionMoney.amount) {
          reduction = remainReductionMoney;
        }

        orderLineItemAppliedDiscount.appliedMoney = MoneyUtils.subtract(orderLineItemAppliedDiscount.appliedMoney, reduction);
        remainReductionMoney = MoneyUtils.subtract(remainReductionMoney, reduction);
        index++;
      });

      if (remainReductionMoney.amount > 0) {
        remainReductionMoney = this.distributeReduction(lineItemAppliedDiscountMap, remainReductionMoney);
      }
    }

    return remainReductionMoney;
  }

  /**
   * Calculates the discount amount for an order.
   *
   * @param discount the discount for calculation
   * @param amountMoney the amount the discount should be applied to
   */
  private calcDiscountAmount(discount: CatalogDiscount, amountMoney: Money): Money {
    let appliedMoney: Money;

    if (discount.discountType === 'FIXED_PERCENTAGE' && discount.percentage !== undefined && discount.percentage !== null) {
      appliedMoney = MoneyUtils.multScalar(discount.percentage / 100.0, amountMoney);
    } else if (discount.discountType === 'FIXED_AMOUNT' && discount.amountMoney !== undefined && discount.amountMoney !== null) {
      appliedMoney = new Money();
      appliedMoney.amount = Math.min(discount.amountMoney.amount, amountMoney.amount);
      appliedMoney.currency = amountMoney.currency;
    }

    return appliedMoney;
  }

  /**
   * Calculates the discount amount for a line item.
   *
   * @param discount the discount for calculation
   * @param lineItem the line item the discount should be applied to
   * @param quantityMax if set then only this many objects within the line item will get the discount
   */
  private calcDiscountAmountPerLineItem(discount: CatalogDiscount, lineItem: OrderLineItem, quantityMax: number | undefined): Money {
    let appliedMoney: Money;

    const baseGrossSalesMoneyOfOne: Money = MoneyUtils.multScalar(1 / lineItem.quantity, lineItem.grossSalesMoney);

    if (discount.discountType === 'FIXED_PERCENTAGE' && discount.percentage !== undefined && discount.percentage !== null) {
      appliedMoney = MoneyUtils.multScalar(discount.percentage / 100.0, baseGrossSalesMoneyOfOne);
    } else if (discount.discountType === 'FIXED_AMOUNT' && discount.amountMoney !== undefined && discount.amountMoney !== null) {
      appliedMoney = new Money();
      appliedMoney.amount = Math.min(discount.amountMoney.amount, baseGrossSalesMoneyOfOne.amount);
      appliedMoney.currency = baseGrossSalesMoneyOfOne.currency;
    }

    if (quantityMax !== undefined && quantityMax < lineItem.quantity) {
      appliedMoney = MoneyUtils.multScalar(quantityMax, appliedMoney);
    } else {
      appliedMoney = MoneyUtils.multScalar(lineItem.quantity, appliedMoney);
    }

    return appliedMoney;
  }

  /**
   * Determines the taxes.
   *
   * @param order the order
   * @param catalogTaxMap the map with CatalogTaxes
   * @param catalogItemMap the map with CatalogItems
   */
  private updateItemLevelTaxes(order: Order, catalogTaxMap: Map<string, CatalogTax>, catalogItemMap: Map<string, CatalogItem>): void {
    // clear all taxes
    this.typeTaxes = [];
    if (order.lineItems) {
      order.lineItems.forEach((lineItem: OrderLineItem) => {
        lineItem.appliedTaxes = [];
        lineItem.totalTaxMoney = new Money();
      });
    }

    const orderLineItemTaxMap: Map<string, OrderLineItemTax> = new Map();

    if (this.typeLineItems) {
      this.typeLineItems.forEach((lineItem: OrderLineItem) => {
        let totalTaxAdditiveMoney = new Money();
        totalTaxAdditiveMoney.currency = lineItem.totalMoney.currency;

        const catalogItem: CatalogItem | undefined = catalogItemMap.get(lineItem.itemId);
        if (catalogItem && catalogItem.taxIds && catalogItem.taxIds.length > 0) {
          const catalogTaxes: CatalogTax[] = [];

          // handle fulfillment type specific tax IDs
          let taxIds: string[] = catalogItem.taxIds;
          if (catalogItem.taxOverrides) {
            const taxOverride: CatalogTaxOverride | undefined = catalogItem.taxOverrides.find((override: CatalogTaxOverride) => {
              return override.fulfillmentType === this.orderingType;
            });
            if (taxOverride && taxOverride.taxIds) {
              taxIds = taxOverride.taxIds;
            }
          }

          for (const taxId of taxIds) {
            const tax: CatalogTax | undefined = catalogTaxMap.get(taxId);
            if (tax) {
              catalogTaxes.push(tax);
            }
          }
          const taxMoneyMap: Map<string, Money> = this.calcTaxAmounts(
            lineItem.totalMoney,
            catalogTaxes.filter((tax: CatalogTax) => tax.inclusionType === 'INCLUSIVE')
          );

          for (const tax of catalogTaxes) {
            let orderLineItemTax: OrderLineItemTax | undefined = orderLineItemTaxMap.get(tax.id);
            if (orderLineItemTax === undefined) {
              orderLineItemTax = new OrderLineItemTax();
              orderLineItemTax.uid = CodeUtils.generateUid();
              orderLineItemTax.catalogTaxId = tax.id;
              orderLineItemTax.name = tax.name;
              orderLineItemTax.scope = 'LINE_ITEM';  // until now there is only LINE_ITEM as scope
              orderLineItemTax.type = tax.inclusionType;
              orderLineItemTax.percentage = tax.percentage;
              orderLineItemTax.appliedMoney = new Money();

              orderLineItemTaxMap.set(orderLineItemTax.catalogTaxId, orderLineItemTax);
            }

            const orderLineItemAppliedTax: OrderLineItemAppliedTax = new OrderLineItemAppliedTax();
            orderLineItemAppliedTax.taxUid = orderLineItemTax.uid;

            if (tax.inclusionType === 'INCLUSIVE') {
              orderLineItemAppliedTax.appliedMoney = taxMoneyMap.get(tax.id);
            } else if (tax.inclusionType === 'ADDITIVE') {
              orderLineItemAppliedTax.appliedMoney = MoneyUtils.multScalar(tax.percentage / 100.0, lineItem.totalMoney);
              totalTaxAdditiveMoney = MoneyUtils.add(totalTaxAdditiveMoney, orderLineItemAppliedTax.appliedMoney);
            }

            if (!lineItem.appliedTaxes) {
              lineItem.appliedTaxes = [];
            }
            lineItem.appliedTaxes.push(orderLineItemAppliedTax);
            orderLineItemTax.appliedMoney = MoneyUtils.add(orderLineItemTax.appliedMoney, orderLineItemAppliedTax.appliedMoney);
          }
        }

        // update line item total
        lineItem.totalTaxMoney = new Money();

        if (lineItem.appliedTaxes) {
          for (const appliedTax of lineItem.appliedTaxes) {
            lineItem.totalTaxMoney = MoneyUtils.add(appliedTax.appliedMoney, lineItem.totalTaxMoney);
          }
        }

        lineItem.totalMoney = MoneyUtils.add(lineItem.totalMoney, totalTaxAdditiveMoney);
      });
    }

    this.typeTaxes = Array.from(orderLineItemTaxMap.values());
  }

  /**
   * Calculates the tax amounts.
   */
  private calcTaxAmounts(grossMoney: Money, taxes: CatalogTax[]): Map<string, Money> {
    const taxMoneyMap: Map<string, Money> = new Map();

    let totalPercentage = 0;
    for (const tax of taxes) {
      totalPercentage += tax.percentage;
    }
    // Calculates the net amount: 100*gross = (100 + p1 + p2 + ... + px)*net
    const netMoney: Money = MoneyUtils.multScalar(100 / (100 + totalPercentage), grossMoney);
    let remainMoney: Money = MoneyUtils.subtract(grossMoney, netMoney);
    for (let i = 0; i < taxes.length; i++) {
      const taxMoney: Money = MoneyUtils.multScalar(taxes[i].percentage / 100.0, netMoney);
      if (i >= taxes.length - 1 || taxMoney.amount > remainMoney.amount) {
        taxMoney.amount = remainMoney.amount;
      }
      remainMoney = MoneyUtils.subtract(remainMoney, taxMoney);

      taxMoneyMap.set(taxes[i].id, taxMoney);
    }

    return taxMoneyMap;
  }

  private updateTotal(shipping?: Money): void {
    this.totalItems = 0;
    this.variationQuantityMap.clear();

    this.typeTotalItems = 0;
    this.typeSubtotal = new Money();
    this.typeTotal = new Money();

    if (this.order) {
      this.order.lineItems.forEach((lineItem: OrderLineItem) => {
        this.totalItems += lineItem.quantity;
        if (this.variationQuantityMap.has(lineItem.itemVariationId)) {
          this.variationQuantityMap.set(lineItem.itemVariationId, this.variationQuantityMap.get(lineItem.itemVariationId) + lineItem.quantity);
        } else {
          this.variationQuantityMap.set(lineItem.itemVariationId, lineItem.quantity);
        }
      });

      this.typeLineItems.forEach((lineItem: OrderLineItem) => {
        this.typeTotalItems += lineItem.quantity;
        this.typeSubtotal = MoneyUtils.add(lineItem.grossSalesMoney, this.typeSubtotal);
      });

      this.typeTotal = MoneyUtils.multScalar(1, this.typeSubtotal);
      this.typeDiscounts.forEach((lineItemDiscount: OrderLineItemDiscount) => {
        this.typeTotal = MoneyUtils.subtract(this.typeTotal, lineItemDiscount.appliedMoney);
      });

      if (shipping) {
        this.typeTotal = MoneyUtils.add(shipping, this.typeTotal);
      }

      this.updateTipAndTotal(this.typeTotal);

      this.typeTotalTip = MoneyUtils.multScalar(1, this.tip);
      this.typeTotal = MoneyUtils.add(this.tip, this.typeTotal);
    }
  }

  /**
   * Smart tip amounts,
   * see https://squareup.com/help/us/en/article/5069-accept-tips-with-the-square-app
   *
   * @param orderTotal the order total
   */
  private updateTipAndTotal(orderTotal: Money): void {
    this.tipOptions.length = 0;

    this.tipOptions.push({ text: 'BASKET_DETAILS.TIP_NO', tip: new Money() });
    if (orderTotal.amount <= 10) {
      this.tipOptions.push({ text: '0,50 €', tip: new Money(0.5) });
      this.tipOptions.push({ text: '1,00 €', tip: new Money(1) });
      this.tipOptions.push({ text: '1,50 €', tip: new Money(1.5) });
    } else {
      this.tipOptions.push({ text: '5 %', tip: MoneyUtils.multScalar(0.05, orderTotal) });
      this.tipOptions.push({ text: '10 %', tip: MoneyUtils.multScalar(0.1, orderTotal) });
      this.tipOptions.push({ text: '15 %', tip: MoneyUtils.multScalar(0.15, orderTotal) });
    }

    if (this.tipOptions.find((tipOption: { text: string, tip: Money }) => tipOption.tip.amount === this.tip.amount) === undefined
      || !(this.location.orderingUseTips && this.location.orderingUseTips.split(',').includes(this.orderingType))) {
      this.tip = new Money();
    }
  }
}
