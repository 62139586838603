
/**
 * An AnalyticsEvent.
 */
export class AnalyticsEvent {

  /** The location's unique ID the object belongs to. */
  public locationId: string;
  /** The user's unique ID the object belongs to. */
  public userId: string;

  public source: string;
  /** The version of the source generating this event. */
  public version: string;

  /** The page the event is created on. Useful for categorizing events (e.g. 'location_details'). */
  public pageLabel: string;
  /** A numeric value associated with the page (e.g. the location ID if it is the location details page). */
  public pageValue?: string;
  /** Typically the object that was interacted with (e.g. 'video'). */
  public category: string;
  /** The type of interaction (e.g. 'play'). */
  public action: string;
  /** Useful for categorizing events (e.g. 'Fall Campaign'). */
  public label?: string;
  /** A numeric value associated with the event (e.g. 42). */
  public value?: string;
  /** Indicates the weight of this event in relation to others. Default is 1. */
  public weight?: number;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

}
