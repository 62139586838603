import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { AnalyticsEvent } from '../models/analytics-event';
import { AnalyticsReport } from '../models/analytics-report';


/**
 * Class providing access methods for analytics.
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsAccess {

  private analyticsEventCollection: AngularFirestoreCollection<AnalyticsEvent>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
  ) {
    this.analyticsEventCollection = afs.collection<AnalyticsEvent>('analyticsEvents');
  }

  /**
   * Add new AnalyticsEvent to cloud.
   *
   * @param analyticsEvent the event to log
   */
  public async logEvent(analyticsEvent: AnalyticsEvent): Promise<void> {
    analyticsEvent.createdAt = new Date().toISOString();

    await this.analyticsEventCollection.add(classToPlain(analyticsEvent) as AnalyticsEvent);
  }

  /**
   * Finds and returns AnalyticsReports.
   *
   * @returns the found AnalyticsReports, otherwise empty list
   */
  public getReports(type?: string, idOfType?: string, source?: string, category?: string, action?: string,
                    pageLabel?: string, pageValue?: string, limit?: number,
                    unit?: string, fromTime?: string, toTime?: string): Observable<AnalyticsReport[]> {
    return this.afs.collection<AnalyticsReport>('analyticsReports', ref => {
      let newRef = ref.orderBy('timestamp', 'asc');

      if (type !== undefined) {
        newRef = newRef.where('type', '==', type);
      }
      if (idOfType !== undefined) {
        newRef = newRef.where('idOfType', '==', idOfType);
      }
      if (source !== undefined) {
        newRef = newRef.where('source', '==', source);
      }
      if (category !== undefined) {
        newRef = newRef.where('category', '==', category);
      }
      if (action !== undefined) {
        newRef = newRef.where('action', '==', action);
      }
      if (pageLabel !== undefined) {
        newRef = newRef.where('pageLabel', '==', pageLabel);
      }
      if (pageValue !== undefined) {
        newRef = newRef.where('pageValue', '==', pageValue);
      }
      if (unit !== undefined) {
        newRef = newRef.where('unit', '==', unit);
      }
      if (fromTime !== undefined) {
        newRef = newRef.where('timestamp', '>=', fromTime);
      }
      if (toTime !== undefined) {
        newRef = newRef.where('timestamp', '<=', toTime);
      }
      if (limit !== undefined) {
        newRef = newRef.limit(limit);
      }

      return newRef;
    }).valueChanges().pipe(
      map((reportsJson) => {
        return reportsJson as AnalyticsReport[]; // plainToClass(AnalyticsReport, reportsJson as object[]);
      })
    );
  }
}
