import { combineLatest, from, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Location } from '../models/location';
import { CatalogMenu } from '../models/catalog-menu';
import { CatalogItem } from '../models/catalog-item';
import { CatalogItemVariation } from '../models/catalog-item-variation';
import { CatalogModifier } from '../models/catalog-modifier';
import { CatalogModifierList } from '../models/catalog-modifier-list';
import { CatalogPricingRule } from '../models/catalog-pricing-rule';
import { CatalogDiscount } from '../models/catalog-discount';
import { CatalogTax } from '../models/catalog-tax';
import { CatalogDiet } from '../models/catalog-diet';
import { Order, OrderStatusConstants } from '../models/order';
import { OrderLineItem, OrderLineItemModifier } from '../models/order-line-item';
import { Money } from '../models/money';
import { Basket } from './basket';
import { Session } from './session.service';
import { LocationManager } from '../manager/location-manager.service';
import { OrderManager } from '../manager/order-manager.service';
import { LocationPriceUtils } from '../utils/location-price-utils';
import { DictUtils } from '../utils/dict-utils';
import { MoneyUtils } from '../utils/money-utils';
import { LocationService } from './location.service';


/**
 * The class represents a shopping basket with necessary functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class BasketService {

  private baskets: Basket[] = [];
  private basketMap: Map<string, ReplaySubject<Basket>> = new Map();
  private locationSubscriptionMap: Map<string, Subscription> = new Map();
  private orderSubscriptionMap: Map<string, Subscription> = new Map();

  constructor(
    private session: Session,
    private locationManager: LocationManager,
    private locationService: LocationService,
    private orderManager: OrderManager
  ) {
  }

  public getBasket(locationId: string): Observable<Basket> {
    if (!this.basketMap.has(locationId)) {
      this.basketMap.set(locationId, new ReplaySubject(1));

      this.initBasket(locationId);
    }

    return this.basketMap.get(locationId).asObservable();
  }

  private initBasket(locationId: string) {
    const basket: Basket = new Basket();

    if (this.orderSubscriptionMap.has(locationId)) {
      this.orderSubscriptionMap.get(locationId).unsubscribe();
    }
    if (this.locationSubscriptionMap.has(locationId)) {
      this.locationSubscriptionMap.get(locationId).unsubscribe();
    }

    this.locationSubscriptionMap.set(
      locationId,
      this.locationManager.getLocation(locationId).pipe(
        switchMap((location: Location) => {
          basket.setLocation(location);

          return combineLatest([
            this.locationService.getCatalogMenus(location.accountId, location.id),
            this.locationService.getCategoryItemMap(location.accountId, location.id),
            this.locationService.getModifierMap(location.accountId, location.id),
            this.locationService.getCatalogDiscounts(location.accountId, location.id),
            this.locationService.getCatalogPricingRules(location.accountId, location.id),
            this.locationService.getCatalogTaxes(location.accountId, location.id),
            this.locationService.getCatalogDietMap(location.accountId, location.id)
          ]);
        })
      ).subscribe((results: (CatalogMenu[] | Map<string, CatalogItem[]>
        | Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }> | CatalogDiscount[] | CatalogPricingRule[] | CatalogTax[]
        | Map<string, CatalogDiet>)[]) => {
        basket.setCatalogObjects(
          results[0] as CatalogMenu[],
          results[1] as Map<string, CatalogItem[]>,
          results[2] as Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }>,
          results[3] as CatalogDiscount[],
          results[4] as CatalogPricingRule[],
          results[5] as CatalogTax[],
          results[6] as Map<string, CatalogDiet>
        );
      })
    );

    // restore order if existent by taking the orderId from browser cache
    const keyInStorage = 'basket_' + locationId;
    this.orderSubscriptionMap.set(
      locationId,
      this.session.getValue(keyInStorage).pipe(
        switchMap((orderId: string | null) => {
          if (orderId === null) {
            // create a new order
            const newOrder: Order = new Order(locationId);
            newOrder.createdBy = 'customer';

            return from(this.orderManager.addOrder(newOrder)).pipe(
              switchMap((id: string) => {
                return from(this.session.setValue(keyInStorage, id, true)).pipe(
                  map(() => {
                    return newOrder;
                  })
                );
              })
            );
          } else {
            return this.orderManager.getOrder(orderId).pipe(catchError(() => {
              // ignore error, mostly in case of insufficient permissions or order status is COMPLETED
              return of(undefined);
            }));
          }
        })
      ).subscribe((order: Order | undefined) => {
        if (order && order.status === OrderStatusConstants.OPEN && !order.placedAt) {
          basket.setOrder(order);

          if (!this.baskets.includes(basket)) {
            this.baskets.push(basket);
            this.basketMap.get(locationId).next(basket);
          }
        } else {
          this.resetBasket(locationId).then();
        }
      })
    );
  }

  public async resetBasket(locationId: string): Promise<void> {
    const keyInStorage = 'basket_' + locationId;
    await this.session.setValue(keyInStorage, null, true);
  }

  public async addItem(locationId: string, catalogItem: CatalogItem, catalogItemVariation: CatalogItemVariation,
                       catalogModifiers?: CatalogModifier[], quantity: number = 1, specialRequests?: string): Promise<void> {
    const basket: Basket = await this.getBasket(locationId).pipe(first()).toPromise();
    const order: Order = basket.getOrder();

    let orderLineItem: OrderLineItem | undefined =
      this.findMatchedLineItem(order, catalogItem, catalogItemVariation, catalogModifiers, 1, specialRequests);
    if (orderLineItem === undefined) {
      orderLineItem = new OrderLineItem();
      orderLineItem.categoryId = catalogItem.categoryId;
      orderLineItem.itemId = catalogItem.id;
      orderLineItem.itemVariationId = catalogItemVariation.id;

      order.lineItems.push(orderLineItem);
    }

    orderLineItem.name = catalogItem.name;
    const variationName: string | null | undefined = DictUtils.getString(catalogItem.strings, catalogItemVariation.name, catalogItem.defaultLang);
    orderLineItem.variationName = variationName ? variationName : '';
    orderLineItem.priceMoney = LocationPriceUtils.getPrice(catalogItemVariation, locationId);  // TODO DEPRECATED
    orderLineItem.basePriceMoney = LocationPriceUtils.getPrice(catalogItemVariation, locationId);

    const newQuantity: number = orderLineItem.quantity + quantity;

    if (catalogModifiers && catalogModifiers.length > 0) {
      orderLineItem.modifiers = [];

      catalogModifiers.forEach((catalogModifier: CatalogModifier) => {
        const modifier: OrderLineItemModifier = new OrderLineItemModifier();
        modifier.modifierId = catalogModifier.id;
        modifier.name = DictUtils.getString(catalogModifier.strings, catalogModifier.name, catalogModifier.defaultLang);
        modifier.basePriceMoney = catalogModifier.priceMoney;
        modifier.totalPriceMoney = MoneyUtils.multScalar(newQuantity, modifier.basePriceMoney);
        orderLineItem.modifiers.push(modifier);
      });
    }

    this.setLineItemQuantity(orderLineItem, newQuantity);

    if (specialRequests) {
      orderLineItem.specialRequests = specialRequests;
    }

    await this.orderManager.addOrder(order);
  }

  public async setNote(locationId: string, catalogItem: CatalogItem, catalogItemVariation: CatalogItemVariation,
                       catalogModifiers: CatalogModifier[], quantity: number, specialRequests: string): Promise<void> {
    const basket: Basket = await this.getBasket(locationId).pipe(first()).toPromise();
    const order: Order = basket.getOrder();

    const orderLineItem: OrderLineItem | undefined =
      this.findMatchedLineItem(order, catalogItem, catalogItemVariation, catalogModifiers, quantity, '');
    if (orderLineItem !== undefined && specialRequests) {

      if (orderLineItem.quantity > quantity) {
        // create new line item
        const newOrderLineItem: OrderLineItem = JSON.parse(JSON.stringify(orderLineItem));
        newOrderLineItem.specialRequests = specialRequests;
        order.lineItems.push(newOrderLineItem);
        this.setLineItemQuantity(newOrderLineItem, quantity);
        this.setLineItemQuantity(orderLineItem, orderLineItem.quantity - quantity);
      } else {
        orderLineItem.specialRequests = specialRequests;
      }

      await this.orderManager.addOrder(order);
    }
  }

  private findMatchedLineItem(order: Order, catalogItem: CatalogItem, catalogItemVariation: CatalogItemVariation,
                              catalogModifiers: CatalogModifier[], minQuantity: number,
                              specialRequests: string): OrderLineItem | undefined {
    return order.lineItems.find((lineItem: OrderLineItem) => {
      let found: boolean = lineItem.itemVariationId === catalogItemVariation.id;

      if (found) {
        found = lineItem.quantity >= minQuantity;
      }

      if (found) {
        const lineItemModifiersLength: number = lineItem.modifiers ? lineItem.modifiers.length : 0;
        const catalogModifiersLength: number = catalogModifiers ? catalogModifiers.length : 0;
        found = lineItemModifiersLength === catalogModifiersLength;
        if (found && lineItem.modifiers) {
          for (const lineItemModifier of lineItem.modifiers) {
            found = catalogModifiers.findIndex((catalogModifier: CatalogModifier) => catalogModifier.id === lineItemModifier.modifierId) >= 0;
            if (!found) {
              break;
            }
          }
        }
      }

      if (found) {
        found = lineItem.specialRequests === specialRequests || (!lineItem.specialRequests && !specialRequests);
      }

      return found;
    });
  }

  private setLineItemQuantity(orderLineItem: OrderLineItem, quantity: number): void {
    orderLineItem.quantity = quantity;

    if (orderLineItem.basePriceMoney) {
      orderLineItem.variationTotalPriceMoney = MoneyUtils.multScalar(orderLineItem.quantity, orderLineItem.basePriceMoney);
    } else {
      orderLineItem.variationTotalPriceMoney = new Money();
    }

    orderLineItem.modifierTotalPriceMoney = new Money();
    if (orderLineItem.modifiers && orderLineItem.modifiers.length > 0) {
      orderLineItem.modifiers.forEach((modifier: OrderLineItemModifier) => {
        modifier.totalPriceMoney = MoneyUtils.multScalar(orderLineItem.quantity, modifier.basePriceMoney);

        orderLineItem.modifierTotalPriceMoney = MoneyUtils.add(modifier.totalPriceMoney, orderLineItem.modifierTotalPriceMoney);
      });
    }

    orderLineItem.grossSalesMoney = MoneyUtils.add(orderLineItem.variationTotalPriceMoney, orderLineItem.modifierTotalPriceMoney);
    orderLineItem.totalMoney = orderLineItem.grossSalesMoney;
  }

  public async removeLineItem(order: Order, lineItem: OrderLineItem): Promise<void> {
    const index: number = order.lineItems.indexOf(lineItem);
    if (index > -1) {
      order.lineItems.splice(index, 1);
    }

    await this.orderManager.addOrder(order);
  }

  public async increaseQuantity(order: Order, lineItem: OrderLineItem, quantity: number = 1): Promise<void> {
    lineItem.quantity += quantity;
    if (lineItem.modifiers) {
      lineItem.modifiers.forEach((modifier: OrderLineItemModifier) => modifier.totalPriceMoney.amount = lineItem.quantity * modifier.basePriceMoney.amount);
    }
    await this.orderManager.addOrder(order);
  }

  public async decreaseQuantity(order: Order, lineItem: OrderLineItem, quantity: number = 1): Promise<void> {
    if (lineItem.quantity > quantity) {
      lineItem.quantity -= quantity;
      if (lineItem.modifiers) {
        lineItem.modifiers.forEach((modifier: OrderLineItemModifier) => modifier.totalPriceMoney.amount = lineItem.quantity * modifier.basePriceMoney.amount);
      }
      await this.orderManager.addOrder(order);
    } else {
      await this.removeLineItem(order, lineItem);
    }
  }
}
