import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NotFoundPage } from './error/notfound/notfound.page';
import { LocationPlaceGuard } from './shared/guard/location-place-guard.service';


const routes: Routes = [
  {path: '', loadChildren: () => import('./location/details/location-details.module').then(m => m.LocationDetailsPageModule)},
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)},
  {path: 'p/:placeId', component: NotFoundPage, canActivate: [LocationPlaceGuard]},
  {path: 'p/:placeId/:any', component: NotFoundPage, canActivate: [LocationPlaceGuard]},
  {path: 'p/:placeId/:any/:thing', component: NotFoundPage, canActivate: [LocationPlaceGuard]},
  {path: 'reservation', loadChildren: () => import('./reservation/reservation.module').then(m => m.ReservationModule)},
  {path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
