import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Location } from '../models/location';
import { LocationPlace } from '../models/location-place';
import { LocationManager } from '../manager/location-manager.service';
import { Session } from '../businessobject/session.service';
import { CheckInPage } from '../../location/details/check-in/check-in.page';


/**
 * Guard redirect to the correct route if LocationPlace id is valid.
 */
@Injectable({
  providedIn: 'root'
})
export class LocationPlaceGuard implements CanActivate {

  /**
   * The default constructor.
   */
  constructor(
    private router: Router,
    private locationManager: LocationManager,
    private session: Session
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const placeId: string | null = route.paramMap.get('placeId');
    let redirect: string;

    if (placeId) {
      const place: LocationPlace | undefined = await this.locationManager.getLocationPlace(placeId).pipe(first()).toPromise();

      if (place && place.visibility === 'visible') {
        const location: Location | undefined = await this.locationManager.getLocation(place.locationId).pipe(first()).toPromise();

        if (location) {
          if (location.provider === 'external' && !!location.providerExternalDineInUrl) {
            window.open(location.providerExternalDineInUrl, '_self');
            return false;
          }

          redirect = `/l/${location.linkName}?ref=qr`;
          await this.session.setValue('locationPlaceId', placeId, true, 7200);

          if (place.metadata && place.metadata.checkIn === 'true') {
            let isCheckedIn = false;

            const checkIn: any | null = await this.session.getValue(CheckInPage.SESSION_CHECK_IN).pipe(first()).toPromise();
            if (checkIn) {
              const diffTime: number = (new Date(checkIn.checkedInAt)).getTime() + (checkIn.durationOfStay * 60000) - (new Date()).getTime();
              if (diffTime > 0) {
                isCheckedIn = true;
              }
            }

            if (!isCheckedIn) {
              redirect = `/l/${location.linkName}/check-in?ref=qr`;
            }
          }
        }
      }
    }

    if (redirect) {
      return this.router.parseUrl(redirect);
    } else {
      return true;
    }
  }
}
