import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { ErrorHandler, Injectable } from '@angular/core';

import { Notifier } from '@airbrake/browser';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';


/**
 * Own global error handler.
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private airbrake: Notifier;
  private reloadToast: HTMLIonToastElement;

  constructor(
    private swUpdate: SwUpdate,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {
    this.airbrake = new Notifier({
      projectId: 268527,
      projectKey: '563c28953243e7de25f55d71220d899f',
      environment: 'production'
    });
  }

  handleError(error: any): void {
    const errorMessageRequestReload = /REQUEST_RELOAD/;
    const errorMessageChunkFailed = /Loading chunk [\d]+ failed/;

    if (errorMessageRequestReload.test(error.message) || errorMessageChunkFailed.test(error.message)) {
      this.requestReload().then();
    } else {
      if (environment.production) {
        this.airbrake.notify(error);
      } else {
        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        throw error;
      }
    }
  }

  private async requestReload(): Promise<void> {
    if (!this.reloadToast) {
      this.reloadToast = await this.toastController.create({
        message: this.translateService.instant('APP.UPDATE_AVAILABLE'),
        position: 'bottom',
        buttons: [{
          side: 'end',
          text: this.translateService.instant('APP.CONFIRM_UPDATE')
        }],
        cssClass: 'app-update-toast'
      });

      await this.reloadToast.present();

      this.reloadToast
        .onDidDismiss()
        .then(() => {
          this.reloadToast = undefined;
          return this.swUpdate.activateUpdate();
        })
        .then(() => window.location.reload());
    }
  }
}
