import { Injectable } from '@angular/core';

import AutocompletePrediction = google.maps.places.AutocompletePrediction;

import { Address } from '../models/address';
import { GoogleAddressAccess } from '../access/google-address-access.service';


/**
 * Class providing management methods for addresses.
 */
@Injectable({
  providedIn: 'root'
})
export class AddressManager {

  /**
   * The default constructor.
   */
  constructor(
    private addressAccess: GoogleAddressAccess
  ) {
  }

  /**
   * Returns address predictions.
   *
   * @param searchString the search string of the targeted address
   * @param addressTypes The types of predictions to be returned. Supported types are 'establishment', 'address', 'geocode'.
   *                     If nothing is specified, all types are returned.
   * @param originLat The latitude of the location where AutocompletePrediction.distance_meters is calculated from.
   * @param originLng The longitude of the location where AutocompletePrediction.distance_meters is calculated from.
   * @returns the address predictions TODO use own data model class
   */
  public async findAddresses(searchString: string,
                             addressTypes?: string[], originLat?: number, originLng?: number): Promise<AutocompletePrediction[]> {
    return this.addressAccess.findAddresses(searchString, addressTypes, originLat, originLng);
  }

  /**
   * Returns address specified by Google's place ID.
   *
   * @param googlePlaceId Google's internal unique place ID of the address
   * @returns the retrieved address
   */
  public async getAddress(googlePlaceId: string): Promise<Address> {
    return this.addressAccess.getAddress(googlePlaceId);
  }

  /**
   * Returns the linear distance between to geo-coordinates.
   *
   * @param originLat The origin latitude.
   * @param originLng The origin longitude.
   * @param destinationLat The destination latitude.
   * @param destinationLng The destination longitude.
   * @returns the computed linear distance in meters
   */
  public async computeLinearDistance(originLat: number, originLng: number, destinationLat: number, destinationLng: number): Promise<number> {
    return this.addressAccess.computeLinearDistance(originLat, originLng, destinationLat, destinationLng);
  }

  /**
   * Returns the distance between to geo-coordinates.
   *
   * @param originLat The origin latitude.
   * @param originLng The origin longitude.
   * @param destinationLat The destination latitude.
   * @param destinationLng The destination longitude.
   * @returns the computed distance as duration in seconds and distance in meters
   */
  public async computeDistance(originLat: number, originLng: number,
                               destinationLat: number, destinationLng: number): Promise<{duration: number, distance: number}> {
    return this.addressAccess.computeDistance(originLat, originLng, destinationLat, destinationLng);
  }
}
