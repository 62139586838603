import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { CatalogMenu } from '../models/catalog-menu';
import { CatalogCategory } from '../models/catalog-category';
import { CatalogItem } from '../models/catalog-item';
import { CatalogItemVariation } from '../models/catalog-item-variation';
import { CatalogImage } from '../models/catalog-image';
import { CatalogTax } from '../models/catalog-tax';
import { CatalogAllergen } from '../models/catalog-allergen';
import { CatalogAdditive } from '../models/catalog-additive';
import { CatalogDiet } from '../models/catalog-diet';
import { CatalogModifierList } from '../models/catalog-modifier-list';
import { CatalogModifier } from '../models/catalog-modifier';
import { CatalogDiscount } from '../models/catalog-discount';
import { CatalogPricingRule } from '../models/catalog-pricing-rule';
import { CatalogLabel } from '../models/catalog-label';
import { CatalogDiscountCode } from '../models/catalog-discount-code';
import { StorageFile } from '../models/storage-file';
import { CatalogAccess } from '../access/catalog-access.service';
import { LocationAccess } from '../access/location-access.service';
// import { CloudStorageManager } from './cloud-storage-manager.service';


/**
 * Class providing management methods for Catalog objects.
 */
@Injectable({
  providedIn: 'root'
})
export class CatalogManager {

  /**
   * The default constructor.
   */
  constructor(
    private catalogAccess: CatalogAccess,
    private locationAccess: LocationAccess,
//    private cloudStorageManager: CloudStorageManager
  ) {
  }

  /**
   * Add new CatalogAllergen to cloud.
   *
   * @param catalogAllergen the CatalogAllergen object to add
   * @return the id of the new CatalogAllergen
   */
  public async addCatalogAllergen(catalogAllergen: CatalogAllergen): Promise<string> {
    return this.catalogAccess.addCatalogAllergen(catalogAllergen);
  }

  /**
   * Returns all CatalogAllergens.
   *
   * @returns the found CatalogAllergens, otherwise empty list
   */
  public getAllCatalogAllergens(): Observable<CatalogAllergen[]> {
    return this.catalogAccess.getAllCatalogAllergens();
  }

  /**
   * Returns the CatalogAllergen specified by the ID.
   *
   * @param catalogAllergenId the CatalogAllergen ID
   * @returns the found CatalogAllergen, otherwise undefined
   */
  public getCatalogAllergen(catalogAllergenId: string): Observable<CatalogAllergen> {
    return this.catalogAccess.getCatalogAllergen(catalogAllergenId);
  }

  /**
   * Removes CatalogAllergens.
   *
   * @param catalogAllergenIds the IDs of the CatalogAllergens to remove
   */
  public async removeCatalogAllergens(catalogAllergenIds: string[]): Promise<void> {
    return this.catalogAccess.removeCatalogAllergens(catalogAllergenIds);
  }

  /**
   * Add new CatalogAdditive to cloud.
   *
   * @param catalogAdditive the CatalogAdditive object to add
   * @return the id of the new CatalogAdditive
   */
  public async addCatalogAdditive(catalogAdditive: CatalogAdditive): Promise<string> {
    return this.catalogAccess.addCatalogAdditive(catalogAdditive);
  }

  /**
   * Returns all CatalogAdditives.
   *
   * @returns the found CatalogAdditives, otherwise empty list
   */
  public getAllCatalogAdditives(): Observable<CatalogAdditive[]> {
    return this.catalogAccess.getAllCatalogAdditives();
  }

  /**
   * Returns the CatalogAdditive specified by the ID.
   *
   * @param catalogAdditiveId the CatalogAdditive ID
   * @returns the found CatalogAdditive, otherwise undefined
   */
  public getCatalogAdditive(catalogAdditiveId: string): Observable<CatalogAdditive> {
    return this.catalogAccess.getCatalogAdditive(catalogAdditiveId);
  }

  /**
   * Removes CatalogAdditives.
   *
   * @param catalogAdditiveIds the IDs of the CatalogAdditives to remove
   */
  public async removeCatalogAdditives(catalogAdditiveIds: string[]): Promise<void> {
    return this.catalogAccess.removeCatalogAdditives(catalogAdditiveIds);
  }

  /**
   * Add new CatalogDiet to cloud.
   *
   * @param catalogDiet the CatalogDiet object to add
   * @return the id of the new CatalogDiet
   */
  public async addCatalogDiet(catalogDiet: CatalogDiet): Promise<string> {
    return this.catalogAccess.addCatalogDiet(catalogDiet);
  }

  /**
   * Returns all CatalogDiets.
   *
   * @returns the found CatalogDiets, otherwise empty list
   */
  public getAllCatalogDiets(): Observable<CatalogDiet[]> {
    return this.catalogAccess.getAllCatalogDiets();
  }

  /**
   * Returns the CatalogDiet specified by the ID.
   *
   * @param catalogDietId the CatalogDiet ID
   * @returns the found CatalogDiet, otherwise undefined
   */
  public getCatalogDiet(catalogDietId: string): Observable<CatalogDiet> {
    return this.catalogAccess.getCatalogDiet(catalogDietId);
  }

  /**
   * Removes CatalogDiets.
   *
   * @param catalogDietIds the IDs of the CatalogDiets to remove
   */
  public async removeCatalogDiets(catalogDietIds: string[]): Promise<void> {
    return this.catalogAccess.removeCatalogDiets(catalogDietIds);
  }

  /**
   * Add new CatalogMenu to cloud.
   *
   * @param catalogMenu the CatalogMenu object to add
   * @return the id of the new CatalogMenu
   */
  public async addCatalogMenu(catalogMenu: CatalogMenu): Promise<string> {
    return this.catalogAccess.addCatalogMenu(catalogMenu);
  }

  /**
   * Returns all CatalogMenus of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogMenus, otherwise empty list
   */
  public getAllCatalogMenus(accountId: string): Observable<CatalogMenu[]> {
    return this.catalogAccess.getAllCatalogMenus(accountId);
  }

  /**
   * Returns the CatalogMenu specified by the ID.
   *
   * @param catalogMenuId the CatalogMenu ID
   * @returns the found CatalogMenu, otherwise undefined
   */
  public getCatalogMenu(catalogMenuId: string): Observable<CatalogMenu> {
    return this.catalogAccess.getCatalogMenu(catalogMenuId);
  }

  /**
   * Removes CatalogMenus.
   *
   * @param catalogMenuIds the IDs of the CatalogMenus to remove
   */
  public async removeCatalogMenus(catalogMenuIds: string[]): Promise<void> {
    // remove images and CatalogItems belonging to the menus
    const itemsToRemove: CatalogItem[] = [];
    const imageFiles: StorageFile[] = [];
    const imageIds: string[] = [];
    for (const catalogMenuId of catalogMenuIds) {
      const catalogMenu: CatalogMenu | undefined = await this.catalogAccess.getCatalogMenu(catalogMenuId).pipe(first()).toPromise();

      if (catalogMenu) {
        const items: CatalogItem[] = await this.catalogAccess.getAllCatalogItems(catalogMenu.accountId).pipe(first()).toPromise();
        const catalogCategoryIds: string[] = catalogMenu.categories ? catalogMenu.categories.map((category: CatalogCategory) => category.id) : [];
        itemsToRemove.push(...items.filter((item: CatalogItem) => catalogCategoryIds.includes(item.categoryId)));

        if (catalogMenu.imageIds) {
          imageIds.push(...catalogMenu.imageIds);
        }
        if (catalogMenu.categories) {
          catalogMenu.categories.forEach((category: CatalogCategory) => {
            if (category.imageIds) {
              imageIds.push(...category.imageIds);
            }
          });
        }
      }
    }
    for (const imageId of imageIds) {
      const image: CatalogImage = await this.catalogAccess.getCatalogImage(imageId).pipe(first()).toPromise();
      imageFiles.push(image);
    }
//    await this.cloudStorageManager.removeFiles(imageFiles);

    await this.removeCatalogItems(itemsToRemove.map((item: CatalogItem) => item.id));

    return this.catalogAccess.removeCatalogMenus(catalogMenuIds);
  }

  /**
   * Changes visibility of CatalogMenus.
   *
   * @param catalogMenuIds the IDs of the CatalogMenus to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogMenus(catalogMenuIds: string[], visible: string): Promise<void> {
    return this.catalogAccess.setVisibilityOfCatalogMenus(catalogMenuIds, visible);
  }

  /**
   * Saves CatalogMenu order.
   *
   * @param catalogMenus the CatalogMenus in the order to save
   */
  public async saveCatalogMenuOrder(catalogMenus: CatalogMenu[]): Promise<void> {
    return this.catalogAccess.saveCatalogMenuOrder(catalogMenus);
  }

  /**
   * Add new CatalogCategory to CatalogMenu.
   *
   * @param catalogMenu the CatalogMenu object the new CatalogCategory to add to
   * @param catalogCategory the CatalogCategory object to add
   * @return the id of the new CatalogCategory
   */
  public async addCatalogCategory(catalogMenu: CatalogMenu, catalogCategory: CatalogCategory): Promise<string> {
    if (!catalogMenu.categories) {
      catalogMenu.categories = [];
    }

    catalogMenu.categories.push(catalogCategory);
    await this.addCatalogMenu(catalogMenu);

    return catalogCategory.id;
  }

  /**
   * Removes CatalogCategories.
   *
   * @param catalogMenu the CatalogMenu the categories to remove from
   * @param catalogCategoryIds the IDs of the CatalogCategories to remove
   */
  public async removeCatalogCategories(catalogMenu: CatalogMenu, catalogCategoryIds: string[]): Promise<void> {
    // remove all items belonging to the category
    const items: CatalogItem[] = await this.catalogAccess.getAllCatalogItems(catalogMenu.accountId).pipe(first()).toPromise();
    const itemsToRemove: CatalogItem[] = items.filter((item: CatalogItem) => catalogCategoryIds.includes(item.categoryId));
    await this.removeCatalogItems(itemsToRemove.map((item: CatalogItem) => item.id));

    if (catalogMenu.categories) {
      catalogMenu.categories = catalogMenu.categories.filter((category: CatalogCategory) => !catalogCategoryIds.includes(category.id));
    }
    await this.addCatalogMenu(catalogMenu);
  }

  /**
   * Add new CatalogItem objects to cloud.
   *
   * @param catalogItems the CatalogItem objects to add
   * @return the IDs of the new CatalogItems
   */
  public async addCatalogItems(catalogItems: CatalogItem[]): Promise<string[]> {
    return await this.catalogAccess.addCatalogItems(catalogItems);
  }

  /**
   * Copies CatalogItems to another CatalogCategory.
   *
   * @param catalogItems the CatalogItem objects to copy
   * @param targetCategoryId the target category the items are copied to
   */
  public async copyCatalogItems(catalogItems: CatalogItem[], targetCategoryId: string): Promise<void> {
    let ordinal = 10000;

    const newCatalogItems: CatalogItem[] = [];
    for (const catalogItem of catalogItems) {
      const newCatalogItem: CatalogItem = JSON.parse(JSON.stringify(catalogItem)) as CatalogItem;

      newCatalogItem.id = null;
      delete newCatalogItem.consecutiveId;
      newCatalogItem.imageIds = null;
      newCatalogItem.ordinal = ordinal;
      newCatalogItem.categoryId = targetCategoryId;
      if (newCatalogItem.variations) {
        newCatalogItem.variations.forEach((variation: CatalogItemVariation) => {
          variation.id = null;
          variation.itemId = null;
          variation.sku = null;
          delete variation.consecutiveId;
          variation.imageIds = [];
        });
      }
      newCatalogItems.push(newCatalogItem);
      ordinal++;
    }

    await this.addCatalogItems(newCatalogItems);
  }

  /**
   * Moves CatalogItems to another CatalogCategory.
   *
   * @param catalogItems the CatalogItem objects to move
   * @param targetCategoryId the target category the items are moved to
   */
  public async moveCatalogItems(catalogItems: CatalogItem[], targetCategoryId: string): Promise<void> {
    await this.catalogAccess.moveCatalogItems(catalogItems, targetCategoryId);
  }

  /**
   * Returns all CatalogItems of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogItems, otherwise empty list
   */
  public getAllCatalogItems(accountId: string): Observable<CatalogItem[]> {
    return this.catalogAccess.getAllCatalogItems(accountId);
  }

  /**
   * Returns all CatalogItems of a location.
   *
   * @param accountId the ID of the account the location belongs to
   * @param locationId the ID of the location
   * @returns the found CatalogItems, otherwise empty list
   */
  public getAllCatalogItemsOfLocation(accountId: string, locationId: string): Observable<CatalogItem[]> {
    return this.catalogAccess.getAllCatalogItems(accountId).pipe(
      map((items: CatalogItem[]) => {
        return items.filter((item: CatalogItem) => {
          const presentAtAllLocations: boolean = item.presentAtAllLocations !== false;

          return presentAtAllLocations || (item.presentAtLocationIds && item.presentAtLocationIds.includes(locationId));
        });
      })
    );
  }

  /**
   * Returns the CatalogItem specified by the ID.
   *
   * @param catalogItemId the CatalogItem ID
   * @returns the found CatalogItem, otherwise undefined
   */
  public getCatalogItem(catalogItemId: string): Observable<CatalogItem | undefined> {
    return this.catalogAccess.getCatalogItem(catalogItemId);
  }

  /**
   * Removes CatalogItems.
   *
   * @param catalogItemIds the IDs of the CatalogItems to remove
   */
  public async removeCatalogItems(catalogItemIds: string[]): Promise<void> {
    // remove images
    const imageFiles: StorageFile[] = [];
    const imageIds: string[] = [];
    for (const catalogItemId of catalogItemIds) {
      const catalogItem: CatalogItem | undefined = await this.catalogAccess.getCatalogItem(catalogItemId).pipe(first()).toPromise();
      if (catalogItem && catalogItem.imageIds) {
        imageIds.push(...catalogItem.imageIds);
      }
    }
    for (const imageId of imageIds) {
      const image: CatalogImage = await this.catalogAccess.getCatalogImage(imageId).pipe(first()).toPromise();
      if (image) {
        imageFiles.push(image);
      }
    }
//    await this.cloudStorageManager.removeFiles(imageFiles);

    return this.catalogAccess.removeCatalogItems(catalogItemIds);
  }

  /**
   * Changes visibility of CatalogItems.
   *
   * @param catalogItemIds the IDs of the CatalogItems to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogItems(catalogItemIds: string[], visible: string): Promise<void> {
    return this.catalogAccess.setVisibilityOfCatalogItems(catalogItemIds, visible);
  }

  /**
   * Saves CatalogItem order.
   *
   * @param catalogItems the CatalogItems in the order to save
   */
  public async saveCatalogItemOrder(catalogItems: CatalogItem[]): Promise<void> {
    return this.catalogAccess.saveCatalogItemOrder(catalogItems);
  }

  /**
   * Add new CatalogImage to cloud.
   *
   * @param catalogImage the CatalogImage object to add
   * @return the id of the new CatalogImage
   */
  public async addCatalogImage(catalogImage: CatalogImage): Promise<string> {
    return this.catalogAccess.addCatalogImage(catalogImage);
  }

  /**
   * Returns the CatalogImage specified by the ID.
   *
   * @param catalogImageId the CatalogImage ID
   * @returns the found CatalogImage, otherwise undefined
   */
  public getCatalogImage(catalogImageId: string): Observable<CatalogImage | undefined> {
    return this.catalogAccess.getCatalogImage(catalogImageId);
  }

  /**
   * Returns all CatalogImages of an account.
   *
   * @param accountId the account ID
   * @returns the found CatalogImages, otherwise empty
   */
  public getAllCatalogImages(accountId: string): Observable<CatalogImage[]> {
    return combineLatest([
      this.getAllCatalogMenus(accountId),
      this.getAllCatalogItems(accountId)
    ]).pipe(
      switchMap(([catalogMenus, catalogItems]) => {
        const promises: Promise<CatalogImage | undefined>[] = [];

        catalogMenus.forEach((catalogMenu: CatalogMenu) => {
          if (catalogMenu.imageIds) {
            for (const imageId of catalogMenu.imageIds) {
              promises.push(this.getCatalogImage(imageId).pipe(first()).toPromise());
            }
          }

          if (catalogMenu.categories) {
            catalogMenu.categories.forEach((catalogCategory: CatalogCategory) => {
              if (catalogCategory.imageIds) {
                for (const imageId of catalogCategory.imageIds) {
                  promises.push(this.getCatalogImage(imageId).pipe(first()).toPromise());
                }
              }
            });
          }
        });

        catalogItems.forEach((catalogItem: CatalogItem) => {
          if (catalogItem.imageIds) {
            for (const imageId of catalogItem.imageIds) {
              promises.push(this.getCatalogImage(imageId).pipe(first()).toPromise());
            }
          }
        });

        return promises.length > 0 ? forkJoin(promises) : of([]);
      })
    );
  }

  /**
   * Returns all CatalogImages of a location.
   *
   * @param accountId the ID of the account the location belongs to
   * @param locationId the location ID
   * @returns the found CatalogImages, otherwise empty
   */
  public getAllCatalogImagesOfLocation(accountId: string, locationId: string): Observable<(CatalogImage | undefined)[]> {
    return this.getAllCatalogItemsOfLocation(accountId, locationId).pipe(
      switchMap((catalogItems: CatalogItem[]) => {
        const promises: Promise<CatalogImage | undefined>[] = [];

        catalogItems.forEach((catalogItem: CatalogItem) => {
          if (catalogItem.imageIds) {
            for (const imageId of catalogItem.imageIds) {
              promises.push(this.getCatalogImage(imageId).pipe(first()).toPromise());
            }
          }
        });

        return promises.length > 0 ? forkJoin(promises) : of([]);
      })
    );
  }

  /**
   * Removes CatalogImages.
   *
   * @param catalogImageIds the IDs of the CatalogImages to remove
   */
  public async removeCatalogImages(catalogImageIds: string[]): Promise<void> {
    return this.catalogAccess.removeCatalogImages(catalogImageIds);
  }

  /**
   * Add new CatalogTax to cloud.
   *
   * @param catalogTax the CatalogTax object to add
   * @return the id of the new CatalogTax
   */
  public async addCatalogTax(catalogTax: CatalogTax): Promise<string> {
    return this.catalogAccess.addCatalogTax(catalogTax);
  }

  /**
   * Returns all CatalogTaxes.
   *
   * @returns the found CatalogTaxes, otherwise empty list
   */
  public getAllCatalogTaxes(): Observable<CatalogTax[]> {
    return this.catalogAccess.getAllCatalogTaxes();
  }

  /**
   * Returns the CatalogTax specified by the ID.
   *
   * @param catalogTaxId the CatalogTax ID
   * @returns the found CatalogTax, otherwise undefined
   */
  public getCatalogTax(catalogTaxId: string): Observable<CatalogTax> {
    return this.catalogAccess.getCatalogTax(catalogTaxId);
  }

  /**
   * Removes CatalogTaxes.
   *
   * @param catalogTaxIds the IDs of the CatalogTaxes to remove
   */
  public async removeCatalogTaxes(catalogTaxIds: string[]): Promise<void> {
    return this.catalogAccess.removeCatalogTaxes(catalogTaxIds);
  }

  /**
   * Returns CatalogItems of a location whose titles match the search text.
   *
   * @param locationId the location id
   * @param searchText the search text
   * @returns the matching CatalogItems, otherwise empty list
   */
  public findCatalogItemsOfLocation(locationId: string, searchText: string): Observable<CatalogItem[]> {
    return this.catalogAccess.findCatalogItemsOfLocation(locationId, searchText);
  }

  /**
   * Add new CatalogModifierList to cloud.
   *
   * @param catalogModifierList the CatalogModifierList object to add
   * @return the id of the new CatalogModifierList
   */
  public async addCatalogModifierList(catalogModifierList: CatalogModifierList): Promise<string> {
    return this.catalogAccess.addCatalogModifierList(catalogModifierList);
  }

  /**
   * Copies a CatalogModifierList with all corresponding CatalogModifiers
   *
   * @param catalogModifierList the CatalogModifierList to copy
   * @param targetName the target name of the new list
   */
  public async copyCatalogModifierList(catalogModifierList: CatalogModifierList, targetName?: string): Promise<void> {
    const ordinal = 10000;

    const newCatalogModifierList: CatalogModifierList = JSON.parse(JSON.stringify(catalogModifierList)) as CatalogModifierList;

    newCatalogModifierList.id = null;
    newCatalogModifierList.ordinal = ordinal;
    if (targetName) {
      newCatalogModifierList.name = targetName;
    }

    newCatalogModifierList.id = await this.addCatalogModifierList(newCatalogModifierList);

    // copy corresponding modifiers
    const newModifiers: CatalogModifier[] = [];
    const modifiers: CatalogModifier[] = await this.catalogAccess.getCatalogModifiersOfList(catalogModifierList.id).pipe(first()).toPromise();
    for (const modifier of modifiers) {
      const newModifier: CatalogModifier = JSON.parse(JSON.stringify(modifier)) as CatalogModifier;
      newModifier.id = null;
      newModifier.modifierListId = newCatalogModifierList.id;
      newModifiers.push(newModifier);
    }
    await this.addCatalogModifiers(newModifiers);
  }

  /**
   * Returns all CatalogModifierLists of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogModifierLists, otherwise empty list
   */
  public getAllCatalogModifierLists(accountId: string): Observable<CatalogModifierList[]> {
    return this.catalogAccess.getAllCatalogModifierLists(accountId);
  }

  /**
   * Returns the CatalogModifierList specified by the ID.
   *
   * @param catalogModifierListId the CatalogModifierList ID
   * @returns the found CatalogModifierList, otherwise undefined
   */
  public getCatalogModifierList(catalogModifierListId: string): Observable<CatalogModifierList> {
    return this.catalogAccess.getCatalogModifierList(catalogModifierListId);
  }

  /**
   * Removes CatalogModifierLists.
   *
   * @param catalogModifierListIds the IDs of the CatalogModifierLists to remove
   */
  public async removeCatalogModifierLists(catalogModifierListIds: string[]): Promise<void> {
    // remove CatalogModifiers belonging to the CatalogModifierList
    const modifiersToRemove: string[] = [];
    for (const catalogModifierListId of catalogModifierListIds) {
      const modifiers: CatalogModifier[] = await this.catalogAccess.getCatalogModifiersOfList(catalogModifierListId).pipe(first()).toPromise();
      const modifierIds: string[] = modifiers.map((modifier: CatalogModifier) => modifier.id);
      modifiersToRemove.push(...modifierIds);
    }

    await this.removeCatalogModifiers(modifiersToRemove);

    return this.catalogAccess.removeCatalogModifierLists(catalogModifierListIds);
  }

  /**
   * Changes visibility of CatalogModifierLists.
   *
   * @param catalogModifierListIds the IDs of the CatalogModifierLists to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogModifierLists(catalogModifierListIds: string[], visible: string): Promise<void> {
    return this.catalogAccess.setVisibilityOfCatalogModifierLists(catalogModifierListIds, visible);
  }

  /**
   * Saves CatalogModifierList order.
   *
   * @param catalogModifierLists the CatalogModifierLists in the order to save
   */
  public async saveCatalogModifierListOrder(catalogModifierLists: CatalogModifierList[]): Promise<void> {
    return this.catalogAccess.saveCatalogModifierListOrder(catalogModifierLists);
  }

  /**
   * Add new CatalogModifier objects to cloud.
   *
   * @param catalogModifiers the CatalogModifier objects to add
   * @return the IDs of the new CatalogModifiers
   */
  public async addCatalogModifiers(catalogModifiers: CatalogModifier[]): Promise<string[]> {
    return await this.catalogAccess.addCatalogModifiers(catalogModifiers);
  }

  /**
   * Returns all CatalogModifiers of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogModifiers, otherwise empty list
   */
  public getAllCatalogModifiers(accountId: string): Observable<CatalogModifier[]> {
    return this.catalogAccess.getAllCatalogModifiers(accountId);
  }

  /**
   * Returns the CatalogModifier specified by the ID.
   *
   * @param catalogModifierId the CatalogModifier ID
   * @returns the found CatalogModifier, otherwise undefined
   */
  public getCatalogModifier(catalogModifierId: string): Observable<CatalogModifier> {
    return this.catalogAccess.getCatalogModifier(catalogModifierId);
  }

  /**
   * Removes CatalogModifiers.
   *
   * @param catalogModifierIds the IDs of the CatalogModifiers to remove
   */
  public async removeCatalogModifiers(catalogModifierIds: string[]): Promise<void> {
    return this.catalogAccess.removeCatalogModifiers(catalogModifierIds);
  }

  /**
   * Changes visibility of CatalogModifiers.
   *
   * @param catalogModifierIds the IDs of the CatalogModifiers to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogModifiers(catalogModifierIds: string[], visible: string): Promise<void> {
    return this.catalogAccess.setVisibilityOfCatalogModifiers(catalogModifierIds, visible);
  }

  /**
   * Saves CatalogModifier order.
   *
   * @param catalogModifiers the CatalogModifiers in the order to save
   */
  public async saveCatalogModifierOrder(catalogModifiers: CatalogModifier[]): Promise<void> {
    return this.catalogAccess.saveCatalogModifierOrder(catalogModifiers);
  }

  /**
   * Adds new CatalogDiscount objects to cloud.
   *
   * @param catalogDiscounts the CatalogDiscount objects to add
   * @return the IDs of the new CatalogDiscounts
   */
  public async addCatalogDiscounts(catalogDiscounts: CatalogDiscount[]): Promise<string[]> {
    return await this.catalogAccess.addCatalogDiscounts(catalogDiscounts);
  }

  /**
   * Returns all CatalogDiscounts of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogDiscounts, otherwise empty list
   */
  public getAllCatalogDiscounts(accountId: string): Observable<CatalogDiscount[]> {
    return this.catalogAccess.getAllCatalogDiscounts(accountId);
  }

  /**
   * Returns the CatalogDiscount specified by the ID.
   *
   * @param catalogDiscountId the CatalogDiscount ID
   * @returns the found CatalogDiscount, otherwise undefined
   */
  public getCatalogDiscount(catalogDiscountId: string): Observable<CatalogDiscount> {
    return this.catalogAccess.getCatalogDiscount(catalogDiscountId);
  }

  /**
   * Removes CatalogDiscounts.
   *
   * @param catalogDiscountIds the IDs of the CatalogDiscounts to remove
   */
  public async removeCatalogDiscounts(catalogDiscountIds: string[]): Promise<void> {
    await this.removeCatalogPricingRulesOfDiscounts(catalogDiscountIds);

    return this.catalogAccess.removeCatalogDiscounts(catalogDiscountIds);
  }

  /**
   * Adds new CatalogPricingRule objects to cloud.
   *
   * @param catalogPricingRules the CatalogPricingRule objects to add
   * @return the IDs of the new CatalogPricingRules
   */
  public async addCatalogPricingRules(catalogPricingRules: CatalogPricingRule[]): Promise<string[]> {
    return await this.catalogAccess.addCatalogPricingRules(catalogPricingRules);
  }

  /**
   * Returns all CatalogPricingRules of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogPricingRules, otherwise empty list
   */
  public getAllCatalogPricingRules(accountId: string): Observable<CatalogPricingRule[]> {
    return this.catalogAccess.getAllCatalogPricingRules(accountId);
  }

  /**
   * Returns the CatalogPricingRules belonging to the CatalogDiscount.
   *
   * @param catalogDiscountId the ID of the CatalogDiscount the CatalogPricingRules belong to
   * @returns the found CatalogPricingRules, otherwise empty
   */
  public getCatalogPricingRulesOfDiscount(catalogDiscountId: string): Observable<CatalogPricingRule[]> {
    return this.catalogAccess.getCatalogPricingRulesOfDiscount(catalogDiscountId);
  }

  /**
   * Removes CatalogPricingRules belonging to CatalogDiscounts.
   *
   * @param catalogDiscountIds the IDs of the CatalogDiscounts the CatalogPricingRules belong to
   */
  public async removeCatalogPricingRulesOfDiscounts(catalogDiscountIds: string[]): Promise<void> {
    const rulesToRemove: string[] = [];

    for (const catalogDiscountId of catalogDiscountIds) {
      const rules: CatalogPricingRule[] = await this.getCatalogPricingRulesOfDiscount(catalogDiscountId).pipe(first()).toPromise();
      const ruleIds: string[] = rules.map((rule: CatalogPricingRule) => rule.id);
      rulesToRemove.push(...ruleIds);
    }

    return await this.catalogAccess.removeCatalogPricingRules(rulesToRemove);
  }

  /**
   * Adds new CatalogDiscountCode objects to cloud.
   *
   * @param catalogDiscountCodes the CatalogDiscountCode objects to add
   * @return the IDs of the new CatalogDiscountCodes
   */
  public async addCatalogDiscountCodes(catalogDiscountCodes: CatalogDiscountCode[]): Promise<string[]> {
    return await this.catalogAccess.addCatalogDiscountCodes(catalogDiscountCodes);
  }

  /**
   * Returns a CatalogDiscountCode by using its code.
   *
   * @param accountId the ID of the account
   * @param code the code of the CatalogDiscountCode
   * @returns the found CatalogDiscountCode, otherwise undefined
   */
  public getCatalogDiscountCodeByCode(accountId: string, code: string): Observable<CatalogDiscountCode | undefined> {
    return this.catalogAccess.getCatalogDiscountCodeByCode(accountId, code);
  }

  /**
   * Returns all CatalogDiscountCodes of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogDiscountCodes, otherwise empty list
   */
  public getAllCatalogDiscountCodes(accountId: string): Observable<CatalogDiscountCode[]> {
    return this.catalogAccess.getAllCatalogDiscountCodes(accountId);
  }

  /**
   * Removes CatalogDiscountCodes.
   *
   * @param catalogDiscountCodeIds the IDs of the CatalogDiscountCodes
   */
  public async removeCatalogDiscountCodes(catalogDiscountCodeIds: string[]): Promise<void> {
    return await this.catalogAccess.removeCatalogDiscountCodes(catalogDiscountCodeIds);
  }

  /**
   * Add new CatalogLabel to cloud.
   *
   * @param catalogLabel the CatalogLabel object to add
   * @return the id of the new CatalogLabel
   */
  public async addCatalogLabel(catalogLabel: CatalogLabel): Promise<string> {
    return this.catalogAccess.addCatalogLabel(catalogLabel);
  }

  /**
   * Returns all CatalogLabels.
   *
   * @param accountId the ID of the account
   * @param type the label type
   * @returns the found CatalogLabels, otherwise empty list
   */
  public getAllCatalogLabels(accountId: string, type: string): Observable<CatalogLabel[]> {
    return this.catalogAccess.getAllCatalogLabels(accountId, type);
  }

  /**
   * Returns the CatalogLabel specified by the ID.
   *
   * @param catalogLabelId the CatalogLabel ID
   * @returns the found CatalogLabel, otherwise undefined
   */
  public getCatalogLabel(catalogLabelId: string): Observable<CatalogLabel> {
    return this.catalogAccess.getCatalogLabel(catalogLabelId);
  }

  /**
   * Removes CatalogLabels.
   *
   * @param catalogLabelIds the IDs of the CatalogLabels to remove
   */
  public async removeCatalogLabels(catalogLabelIds: string[]): Promise<void> {
    return this.catalogAccess.removeCatalogLabels(catalogLabelIds);
  }
}
