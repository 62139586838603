import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

import { Location } from '../models/location';
import { CatalogMenu } from '../models/catalog-menu';
import { CatalogCategory } from '../models/catalog-category';
import { CatalogItem } from '../models/catalog-item';
import { CatalogModifierList } from '../models/catalog-modifier-list';
import { CatalogModifier } from '../models/catalog-modifier';
import { CatalogPricingRule } from '../models/catalog-pricing-rule';
import { CatalogDiscount } from '../models/catalog-discount';
import { CatalogAllergen } from '../models/catalog-allergen';
import { CatalogAdditive } from '../models/catalog-additive';
import { CatalogDiet } from '../models/catalog-diet';
import { CatalogTax } from '../models/catalog-tax';
import { CatalogImage } from '../models/catalog-image';
import { LocationManager } from '../manager/location-manager.service';
import { CatalogManager } from '../manager/catalog-manager.service';


class LocationData {
  public location$: ReplaySubject<Location> = new ReplaySubject(1);
  public locationSubscription?: Subscription;
  public menus$: BehaviorSubject<CatalogMenu[]> = new BehaviorSubject(undefined);
  public menusSubscription?: Subscription;
  public categoryItemMap$: BehaviorSubject<Map<string, CatalogItem[]>> = new BehaviorSubject(undefined);
  public categoryItemMapSubscription?: Subscription;
  public imageMap$: BehaviorSubject<Map<string, CatalogImage>> = new BehaviorSubject(undefined);
  public imageMapSubscription?: Subscription;
  public modifierMap$: BehaviorSubject<Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }>> = new BehaviorSubject(undefined);
  public modifierMapSubscription?: Subscription;
  public allergenMap$: BehaviorSubject<Map<string, CatalogAllergen>> = new BehaviorSubject(undefined);
  public allergenMapSubscription?: Subscription;
  public additiveMap$: BehaviorSubject<Map<string, CatalogAdditive>> = new BehaviorSubject(undefined);
  public additiveMapSubscription?: Subscription;
  public dietMap$: BehaviorSubject<Map<string, CatalogDiet>> = new BehaviorSubject(undefined);
  public dietMapSubscription?: Subscription;
  public discounts$: BehaviorSubject<CatalogDiscount[]> = new BehaviorSubject(undefined);
  public discountsSubscription?: Subscription;
  public pricingRules$: BehaviorSubject<CatalogPricingRule[]> = new BehaviorSubject(undefined);
  public pricingRulesSubscription?: Subscription;
  public taxes$: BehaviorSubject<CatalogTax[]> = new BehaviorSubject(undefined);
  public taxesSubscription?: Subscription;
}

/**
 * The class represents a Catalog with related menus, categories, item, ....
 */
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private locationDataMap: Map<string, LocationData> = new Map();

  constructor(
    private locationManager: LocationManager,
    private catalogManager: CatalogManager
  ) {
  }

  public getLocation(locationId: string): Observable<Location | undefined> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).locationSubscription) {
      this.locationDataMap.get(locationId).locationSubscription =
        this.locationManager.getLocation(locationId).subscribe((location: Location) => {
          this.locationDataMap.get(locationId).location$.next(location);
        });
    }

    return this.locationDataMap.get(locationId).location$.asObservable();
  }

  public getCatalogMenus(accountId: string, locationId: string): Observable<CatalogMenu[]> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).menusSubscription) {
      this.locationDataMap.get(locationId).menusSubscription = this.catalogManager.getAllCatalogMenus(accountId)
        .subscribe((menus: CatalogMenu[]) => {
          this.locationDataMap.get(locationId).menus$.next(menus);
        });
    }

    return this.locationDataMap.get(locationId).menus$.pipe(filter(value => value !== undefined));
  }

  public getCategoryItemMap(accountId: string, locationId: string): Observable<Map<string, CatalogItem[]>> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).categoryItemMapSubscription) {
      const subscription: Subscription = this.catalogManager.getAllCatalogItemsOfLocation(accountId, locationId).subscribe((catalogItems: CatalogItem[]) => {
        const categoryItemMap: Map<string, CatalogItem[]> = new Map();

        catalogItems.forEach((item: CatalogItem) => {
          const categoryId: string = item.categoryId;
          if (categoryId) {
            if (!categoryItemMap.has(categoryId)) {
              categoryItemMap.set(categoryId, []);
            }

            categoryItemMap.get(categoryId).push(item);
          }
        });
        this.locationDataMap.get(locationId).categoryItemMap$.next(categoryItemMap);
      });

      this.locationDataMap.get(locationId).categoryItemMapSubscription = subscription;
    }

    return this.locationDataMap.get(locationId).categoryItemMap$.pipe(filter(value => value !== undefined));
  }

  public getCatalogImageMap(accountId: string, locationId: string): Observable<Map<string, CatalogImage>> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).imageMapSubscription) {
      const subscription: Subscription = combineLatest([
        this.catalogManager.getAllCatalogMenus(accountId),
        this.getCategoryItemMap(accountId, locationId),
        this.catalogManager.getAllCatalogImages(accountId)
      ]).subscribe(([catalogMenus, categoryItemMap, catalogImages]) => {
        const catalogImageMapOfLocation: Map<string, CatalogImage> = new Map();

        const catalogImageMap: Map<string, CatalogImage> = new Map();
        catalogImages.forEach((image) => {
          if (image) {
            catalogImageMap.set(image.id, image);
          }
        });

        catalogMenus.forEach((catalogMenu: CatalogMenu) => {
          if (catalogMenu.visibility !== 'hidden') {
            if (catalogMenu.imageIds) {
              for (const imageId of catalogMenu.imageIds) {
                const image: CatalogImage | undefined = catalogImageMap.get(imageId);
                if (image) {
                  catalogImageMapOfLocation.set(image.id, image);
                }
              }
            }

            if (catalogMenu.categories) {
              catalogMenu.categories.forEach((catalogCategory: CatalogCategory) => {
                if (catalogCategory.visibility !== 'hidden' && catalogCategory.imageIds) {
                  for (const imageId of catalogCategory.imageIds) {
                    const image: CatalogImage | undefined = catalogImageMap.get(imageId);
                    if (image) {
                      catalogImageMapOfLocation.set(image.id, image);
                    }
                  }
                }
              });
            }
          }
        });

        categoryItemMap.forEach((catalogItems: CatalogItem[]) => {
          catalogItems.forEach((catalogItem: CatalogItem) => {
            if (catalogItem.visibility !== 'hidden' && catalogItem.imageIds) {
              for (const imageId of catalogItem.imageIds) {
                const image: CatalogImage | undefined = catalogImageMap.get(imageId);
                if (image) {
                  catalogImageMapOfLocation.set(image.id, image);
                }
              }
            }
          });
        });

        this.locationDataMap.get(locationId).imageMap$.next(catalogImageMapOfLocation);
      });

      this.locationDataMap.get(locationId).imageMapSubscription = subscription;
    }

    return this.locationDataMap.get(locationId).imageMap$.pipe(filter(value => value !== undefined));
  }

  public getModifierMap(accountId: string, locationId: string): Observable<Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }>> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).modifierMapSubscription) {
      const subscription: Subscription = combineLatest([
        accountId !== undefined ? this.catalogManager.getAllCatalogModifierLists(accountId) : of([]),
        accountId !== undefined ? this.catalogManager.getAllCatalogModifiers(accountId) : of([])
      ]).subscribe((results: (CatalogModifierList[] | CatalogModifier[])[]) => {
        const modifierLists: CatalogModifierList[] = results[0] as CatalogModifierList[];
        const modifiers: CatalogModifier[] = results[1] as CatalogModifier[];

        const modifierMap: Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }> = new Map();

        modifierLists.forEach((modifierList: CatalogModifierList) => {
          if (modifierList.visibility === 'visible') {
            modifierMap.set(modifierList.id, { modifierList, modifiers: [] });
          }
        });
        modifiers.forEach((modifier: CatalogModifier) => {
          if (modifier.visibility === 'visible') {
            if (modifierMap.has(modifier.modifierListId)) {
              modifierMap.get(modifier.modifierListId).modifiers.push(modifier);
            }
          }
        });

        this.locationDataMap.get(locationId).modifierMap$.next(modifierMap);
      });

      this.locationDataMap.get(locationId).modifierMapSubscription = subscription;
    }

    return this.locationDataMap.get(locationId).modifierMap$.pipe(filter(value => value !== undefined));
  }

  public getCatalogAllergenMap(accountId: string, locationId: string): Observable<Map<string, CatalogAllergen>> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).allergenMapSubscription) {
      this.locationDataMap.get(locationId).allergenMapSubscription =
        this.catalogManager.getAllCatalogAllergens().subscribe((allergens: CatalogAllergen[]) => {
          const allergenMap: Map<string, CatalogAllergen> = new Map();
          allergens.forEach((allergen: CatalogAllergen) => allergenMap.set(allergen.id, allergen));
          this.locationDataMap.get(locationId).allergenMap$.next(allergenMap);
        });
    }

    return this.locationDataMap.get(locationId).allergenMap$.pipe(filter(value => value !== undefined));
  }

  public getCatalogAdditiveMap(accountId: string, locationId: string): Observable<Map<string, CatalogAdditive>> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).additiveMapSubscription) {
      this.locationDataMap.get(locationId).additiveMapSubscription =
        this.catalogManager.getAllCatalogAdditives().subscribe((additives: CatalogAdditive[]) => {
          const additiveMap: Map<string, CatalogAdditive> = new Map();
          additives.forEach((additive: CatalogAdditive) => additiveMap.set(additive.id, additive));
          this.locationDataMap.get(locationId).additiveMap$.next(additiveMap);
        });
    }

    return this.locationDataMap.get(locationId).additiveMap$.pipe(filter(value => value !== undefined));
  }

  public getCatalogDietMap(accountId: string, locationId: string): Observable<Map<string, CatalogDiet>> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).dietMapSubscription) {
      this.locationDataMap.get(locationId).dietMapSubscription =
        this.catalogManager.getAllCatalogDiets().subscribe((diets: CatalogDiet[]) => {
          const dietMap: Map<string, CatalogDiet> = new Map();
          diets.forEach((diet: CatalogDiet) => dietMap.set(diet.id, diet));
          this.locationDataMap.get(locationId).dietMap$.next(dietMap);
        });
    }

    return this.locationDataMap.get(locationId).dietMap$.pipe(filter(value => value !== undefined));
  }

  public getCatalogDiscounts(accountId: string, locationId: string): Observable<CatalogDiscount[]> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).discountsSubscription) {
      this.locationDataMap.get(locationId).discountsSubscription =
        this.catalogManager.getAllCatalogDiscounts(accountId).subscribe((discounts: CatalogDiscount[]) => {
          this.locationDataMap.get(locationId).discounts$.next(discounts);
        });
    }

    return this.locationDataMap.get(locationId).discounts$.pipe(filter(value => value !== undefined));
  }

  public getCatalogPricingRules(accountId: string, locationId: string): Observable<CatalogPricingRule[]> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).pricingRulesSubscription) {
      this.locationDataMap.get(locationId).pricingRulesSubscription =
        this.catalogManager.getAllCatalogPricingRules(accountId).subscribe((pricingRules: CatalogPricingRule[]) => {
          this.locationDataMap.get(locationId).pricingRules$.next(pricingRules);
        });
    }

    return this.locationDataMap.get(locationId).pricingRules$.pipe(filter(value => value !== undefined));
  }

  public getCatalogTaxes(accountId: string, locationId: string): Observable<CatalogTax[]> {
    if (!this.locationDataMap.has(locationId)) {
      this.locationDataMap.set(locationId, new LocationData());
    }

    if (!this.locationDataMap.get(locationId).taxesSubscription) {
      this.locationDataMap.get(locationId).taxesSubscription =
        this.catalogManager.getAllCatalogTaxes().subscribe((taxes: CatalogTax[]) => {
          this.locationDataMap.get(locationId).taxes$.next(taxes);
        });
    }

    return this.locationDataMap.get(locationId).taxes$.pipe(filter(value => value !== undefined));
  }
}
