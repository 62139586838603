/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';

import PlaceResult = google.maps.places.PlaceResult;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import AutocompletionRequest = google.maps.places.AutocompletionRequest;
import DistanceMatrixRequest = google.maps.DistanceMatrixRequest;
import TravelMode = google.maps.TravelMode;

import { Address } from '../models/address';


/**
 * Class providing access methods for the Google Maps API.
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAddressAccess {

  private autocompleteService: google.maps.places.AutocompleteService;
  private placesService: google.maps.places.PlacesService;
  private distanceMatrixService: google.maps.DistanceMatrixService;

  /**
   * The default constructor.
   */
  constructor(
  ) {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.distanceMatrixService = new google.maps.DistanceMatrixService();
  }

  /**
   * Returns address predictions.
   *
   * @param searchString the search string of the targeted address
   * @param addressTypes The types of predictions to be returned. Supported types are 'establishment', 'address', 'geocode'.
   *                     If nothing is specified, all types are returned.
   * @param originLat The latitude of the location where AutocompletePrediction.distance_meters is calculated from.
   * @param originLng The longitude of the location where AutocompletePrediction.distance_meters is calculated from.
   * @returns the address predictions TODO use own data model class
   */
  public async findAddresses(searchString: string,
                             addressTypes?: string[], originLat?: number, originLng?: number): Promise<AutocompletePrediction[]> {
    const request: AutocompletionRequest = {
      input: searchString,
//      componentRestrictions: { country: 'de' },
      types: addressTypes
    };
    if (originLat !== undefined && originLng !== undefined) {
      request.origin = new google.maps.LatLng(originLat, originLng);
    }

    return new Promise<AutocompletePrediction[]>((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(request, (result, status) => {
        if (status === 'OK') {
          resolve(result);
        } else {
          reject(status);
        }
      });
    });
  }

  /**
   * Returns address specified by Google's place ID.
   *
   * @param googlePlaceId Google's internal unique place ID of the address
   * @returns the retrieved address
   */
  public async getAddress(googlePlaceId: string): Promise<Address> {
    const address: Address = new Address();

    const place: PlaceResult = await new Promise((resolve, reject) => {
      this.placesService.getDetails({
        placeId: googlePlaceId
      }, (result, status) => {
        if (status === 'OK') {
          resolve(result);
        } else {
          reject(status);
        }
      });
    });

    let street: string;
    let streetNumber: string;
    for (const component of place.address_components) {
      if (component.types.includes('route')) {
        street = component.long_name;
      }
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      }
      if (component.types.includes('locality')) {
        address.locality = component.long_name;
      }
      if (component.types.includes('sublocality_level_1')) {
        address.sublocality = component.long_name;
      }
      if (component.types.includes('sublocality_level_2')) {
        address.sublocality2 = component.long_name;
      }
      if (component.types.includes('sublocality_level_3')) {
        address.sublocality3 = component.long_name;
      }
      if (component.types.includes('administrative_area_level_1')) {
        address.administrativeDistrictLevel1 = component.long_name;
      }
      if (component.types.includes('administrative_area_level_2')) {
        address.administrativeDistrictLevel2 = component.long_name;
      }
      if (component.types.includes('administrative_area_level_3')) {
        address.administrativeDistrictLevel3 = component.long_name;
      }
      if (component.types.includes('postal_code')) {
        address.postalCode = component.long_name;
      }
      if (component.types.includes('country')) {
        address.country = component.short_name;
        address.countryName = component.long_name;
      }
    }
    if (street) {
      address.addressLine1 = streetNumber ? `${street} ${streetNumber}` : street;
    }
    if (place.types.includes('establishment')) {
      address.organization = place.name;
    }
    address.lat = place.geometry.location.lat();
    address.lng = place.geometry.location.lng();

    return address;
  }

  /**
   * Returns the linear distance between to geo-coordinates.
   *
   * @param originLat The origin latitude.
   * @param originLng The origin longitude.
   * @param destinationLat The destination latitude.
   * @param destinationLng The destination longitude.
   * @returns the computed linear distance in meters
   */
  public async computeLinearDistance(originLat: number, originLng: number, destinationLat: number, destinationLng: number): Promise<number> {
    const latLng1: google.maps.LatLng = new google.maps.LatLng(originLat, originLng);
    const latLng2: google.maps.LatLng = new google.maps.LatLng(destinationLat, destinationLng);
    return google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2);
  }

  /**
   * Returns the driving distance between to geo-coordinates.
   *
   * @param originLat The origin latitude.
   * @param originLng The origin longitude.
   * @param destinationLat The destination latitude.
   * @param destinationLng The destination longitude.
   * @returns the computed distance as duration in seconds and distance in meters
   */
  public async computeDistance(originLat: number, originLng: number,
                               destinationLat: number, destinationLng: number): Promise<{duration: number, distance: number}> {
    const request: DistanceMatrixRequest = {
      origins: [new google.maps.LatLng(originLat, originLng)],
      destinations: [new google.maps.LatLng(destinationLat, destinationLng)],
      travelMode: TravelMode.DRIVING
    };

    return new Promise<{duration: number, distance: number}>((resolve, reject) => {
      this.distanceMatrixService.getDistanceMatrix(request, (result, status) => {
        if (status === 'OK') {
          let duration: number;
          let distance: number;

          for (const element of result.rows[0].elements) {
            if (element.status === 'OK') {
              duration = element.duration.value;
              distance = element.distance.value;
              break;
            }
          }
          resolve({duration, distance});
        } else {
          reject(status);
        }
      });
    });
  }
}
