import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';

import { CheckIn } from '../models/check-in';
import { environment } from '../../../environments/environment';


/**
 * Class providing database methods for check-ins.
 */
@Injectable({
  providedIn: 'root'
})
export class CheckInAccess {

  private checkInCollection: AngularFirestoreCollection<CheckIn>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
    private http: HttpClient
  ) {
    this.checkInCollection = afs.collection<CheckIn>('checkIns');
  }

  /**
   * Add new CheckIn to cloud.
   *
   * @param checkIn the CheckIn object to add
   * @return the id of the new CheckIn
   */
  public async addCheckIn(checkIn: CheckIn): Promise<string> {
    if (!checkIn.id) {
      checkIn.id = this.afs.createId();
      checkIn.createdAt = new Date().toISOString();
    }
    checkIn.updatedAt = new Date().toISOString();

    await this.checkInCollection.doc(checkIn.id).set(classToPlain(checkIn) as CheckIn, { merge: true });

    return checkIn.id;
  }

  /**
   * Returns all CheckIns of a location.
   *
   * @param locationId the ID of the location
   * @returns the found CheckIns, otherwise empty list
   */
  public getAllCheckIns(locationId: string): Observable<CheckIn[]> {
    return this.afs.collection<CheckIn>('checkIns', ref => ref.where('locationId', '==', locationId).orderBy('checkedInAt'))
      .valueChanges().pipe(
        map((checkInsJson) => {
          return checkInsJson as CheckIn[]; // plainToClass(CheckIn, checkInsJson as object[]);
        })
      );
  }

  /**
   * Returns the CheckIn specified by the ID.
   *
   * @param checkInId the CheckIn ID
   * @returns the found CheckIn, otherwise undefined
   */
  public getCheckIn(checkInId: string): Observable<CheckIn> {
    return this.checkInCollection.doc(checkInId).valueChanges().pipe(
      map((checkInJson) => {
        return checkInJson as CheckIn; // plainToClass(CheckIn, checkInJson);
      })
    );
  }

  /**
   * Removes CheckIns.
   *
   * @param checkInIds the IDs of the CheckIns to remove
   */
  public async removeCheckIns(checkInIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const checkInId of checkInIds) {
      batch.delete(this.checkInCollection.doc(checkInId).ref);
    }

    await batch.commit();
  }

  /**
   * Changes status of CheckIns.
   *
   * @param checkInIds the IDs of the CheckIns to change
   * @param status possible values are 'CHECKED_IN', 'CHECKED_OUT'
   */
  public async setStatusOfCheckIns(checkInIds: string[], status: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const checkInId of checkInIds) {
      batch.update(this.checkInCollection.doc(checkInId).ref, { status });
    }

    await batch.commit();
  }

  /**
   * Sets status of a CheckIn object to CHECKED_OUT.
   *
   * @param checkInId the ID of the checkIn object to check-out for
   */
  public async checkOutGuest(checkInId: string): Promise<void> {
    const body: any = {
      check_in_id: checkInId
    };

    this.http.post(
      environment.firebaseEndpoint.checkOutGuest,
      JSON.stringify(body)
    ).subscribe(() => {
      // ignore
    });
  }
}
