
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appId: 'menu_manager_dev',
  production: false,
  app: {
    version: '1.0.0-1',
    rootUrl: 'http://localhost:8100',
    defaultLang: 'de',
    defaultTimezone: 'Europe/Berlin'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBS25k1211ov8P1U_ZV0VwH8Tukvmw0xGA",
    authDomain: "menu-manager-dev.firebaseapp.com",
    projectId: "menu-manager-dev",
    storageBucket: "menu-manager-dev.appspot.com",
    messagingSenderId: "699829065192",
    appId: "1:699829065192:web:3459a55b369a75d100005d"
  },
  auth: {
    useEmailVerification: false
  },
  location: {
    link: 'http://localhost:8100/l/{{locationLinkName}}',
    linkWithItem: 'http://localhost:8100/l/{{locationLinkName}}?itemId={{itemId}}'
  },
  cloudinaryConfig: {
    cloudName: 'dhisro6k4',
    imageRefName: 'cloudinary'
  },
  stripePaymentConfig: {
    apiPublicKey: 'pk_test_QhP2AHSvSmpiJS1G6A1FY3Wh002fNVXDai',
    apiUsername: 'stripe-dev',
    apiPassword: 'QIP8wGVvcd3Ogg1agjpP',
    createOrderSessionUrl: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/stripeCreateOrderSessionEur',
    capturePaymentUrl: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/stripe/v1/CapturePayment'
  },
  paypalPaymentConfig: {
    clientId: 'AeLLOsqsVT0op9Z8nx4W3TJtrrWiAo69Fzimhigmmc4j7UvJEoCdCuthP0Nk5CCkvU032GdRppNMfWvd',
    apiUsername: 'paypal-dev',
    apiPassword: 'dtCwrPVkSDs4dq413QOs',
    createOrderSessionUrl: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/paypal/v1/CreateOrderSession',
    capturePaymentUrl: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/paypal/v1/CapturePayment'
  },
  firebaseEndpoint: {
    apiKey: 'CvhFIxtlcXx1uydZIXyR',
    checkOutGuest: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/checkOutGuest',
    sendVerificationCode: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/sendVerificationCode',
    getReservationByCancellationCode: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/api/reservation/cancelcode'
  },
  accountBusinessTypeUi: {
    RESTAURANT: {
      id: 'RESTAURANT', langKeyPrefix: '', showZeroMoney: true, showLocationAddress: true,
      imageSize: 'M', skipAddressPrompt: false, hidePreferredTime: false
    },
    RESTAURANT_MENU: {
      id: 'RESTAURANT_MENU', langKeyPrefix: '', showZeroMoney: true, showLocationAddress: true,
      imageSize: 'M', skipAddressPrompt: false, hidePreferredTime: false
    },
    SHOP: {
      id: 'SHOP', langKeyPrefix: 'SHOP.', showZeroMoney: true, showLocationAddress: false,
      imageSize: 'L', skipAddressPrompt: true, hidePreferredTime: false
    },
    ACCOMMODATION: {
      id: 'ACCOMMODATION', langKeyPrefix: 'ACCOMMODATION.', showZeroMoney: true, showLocationAddress: false,
      imageSize: 'M', skipAddressPrompt: false, hidePreferredTime: false
    },
    ACCOMMODATION_MANAGED: {
      id: 'ACCOMMODATION_MANAGED', langKeyPrefix: 'MANAGED.ACCOMMODATION.', showZeroMoney: false, showLocationAddress: false,
      imageSize: 'M', skipAddressPrompt: false, hidePreferredTime: true
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
