import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Subject, combineLatest } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { OverlayEventDetail, ToastButton } from '@ionic/core';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';
import { Account } from '../../shared/models/account';
import { Location } from '../../shared/models/location';
import { CatalogItem, CatalogModifierListInfo } from '../../shared/models/catalog-item';
import { CatalogItemVariation } from '../../shared/models/catalog-item-variation';
import { CatalogImage } from '../../shared/models/catalog-image';
import { CatalogAllergen } from '../../shared/models/catalog-allergen';
import { CatalogAdditive } from '../../shared/models/catalog-additive';
import { CatalogDiet } from '../../shared/models/catalog-diet';
import { CatalogModifier } from '../../shared/models/catalog-modifier';
import { CatalogModifierList } from '../../shared/models/catalog-modifier-list';
import { Session } from '../../shared/businessobject/session.service';
import { AnalyticsManager } from '../../shared/manager/analytics-manager.service';
import { BasePage } from '../../shared/base.page';
import { SelectModifierPage } from '../../location/details/select-modifier/select-modifier.page';
import { TextEditComponent } from '../../shared/modal/text-edit/text-edit.component';
import { DictUtils } from '../../shared/utils/dict-utils';
import { BasketService } from '../../shared/businessobject/basket-service';
import { LocationService } from '../../shared/businessobject/location.service';
import { LocationPriceUtils } from '../../shared/utils/location-price-utils';


@Component({
  selector: 'app-dish-details',
  templateUrl: 'dish-details.page.html',
  styleUrls: ['dish-details.page.scss']
})
export class DishDetailsPage extends BasePage implements OnInit, OnDestroy {

  public preferredLanguage: string | undefined;

  public locationPriceUtils = LocationPriceUtils;

  @Input() account: Account;
  @Input() location: Location;
  @Input() item: CatalogItem;

  public businessTypeUi = environment.accountBusinessTypeUi.RESTAURANT;

  private modifierMap: Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }> = new Map();
  public imageMap: Map<string, CatalogImage> = new Map();
  public catalogAllergenMap: Map<string, CatalogAllergen> = new Map<string, CatalogAllergen>();
  public catalogAdditiveMap: Map<string, CatalogAdditive> = new Map<string, CatalogAdditive>();
  public catalogDietMap: Map<string, CatalogDiet> = new Map<string, CatalogDiet>();
  private stop$: Subject<boolean> = new Subject<boolean>();

  public noteToast?: HTMLIonToastElement;

  constructor(
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager,
    private platform: Platform,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private locationService: LocationService,
    private toastController: ToastController,
    private session: Session,
    private basketService: BasketService
  ) {
    super('dish_details', translateService, alertController, loadingController, analyticsManager);
  }

  ionViewDidEnter() {
    this.logPageView(this.pageLabel, this.item.id);
  }

  async ngOnInit() {
    this.businessTypeUi = this.account.businessType ? environment.accountBusinessTypeUi[this.account.businessType] : this.businessTypeUi;

    this.session.getValue(this.session.PREFERRED_LANGUAGE).pipe(
      takeUntil(this.stop$)
    ).subscribe((value: string) => {
      this.preferredLanguage = value;
    });

    combineLatest([
      this.locationService.getModifierMap(this.location.accountId, this.location.id),
      this.locationService.getCatalogImageMap(this.location.accountId, this.location.id)
    ]).pipe(
      takeUntil(this.stop$)
    ).subscribe((results: (Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }> | Map<string, CatalogImage>)[]) => {
      this.modifierMap = results[0] as Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }>;
      this.imageMap = results[1] as Map<string, CatalogImage>;
    });

    combineLatest([
      this.locationService.getCatalogAllergenMap(this.location.accountId, this.location.id),
      this.locationService.getCatalogAdditiveMap(this.location.accountId, this.location.id),
      this.locationService.getCatalogDietMap(this.location.accountId, this.location.id)
    ]).pipe(
      takeUntil(this.stop$)
    ).subscribe(([catalogAllergenMap, catalogAdditiveMap, catalogDietMap]) => {
      this.catalogAllergenMap = catalogAllergenMap;
      this.catalogAdditiveMap = catalogAdditiveMap;
      this.catalogDietMap = catalogDietMap;
    });
  }

  public ngOnDestroy(): void {
    if (this.noteToast) {
      this.noteToast.dismiss().then();
      this.noteToast = undefined;
    }
    this.stop$.next(true);
    this.stop$.complete();
  }

  public async share($event: MouseEvent, itemId: string): Promise<void> {
    const url: string = environment.location.linkWithItem
      .replace('{{locationLinkName}}', this.location.linkName)
      .replace('{{itemId}}', itemId);
    const params: any = { locationName: this.location.name, itemName: this.item.name };
    const message: string = this.translateService.instant('DISH_DETAILS.SHARE_TEXT', params);
    const subject: string = this.translateService.instant('DISH_DETAILS.SHARE_SUBJECT', params);

    await this.shareViaBrowser(url, subject, message);

    $event.stopPropagation();
  }

  private async shareViaBrowser(url: string, subject: string, message: string): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      header: 'Share',
      buttons: [{
        text: 'Facebook',
        icon: 'logo-facebook',
        handler: () => {
          const facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
            'facebook-popup',
            'height=350,width=600');
          if (facebookWindow.focus) {
            facebookWindow.focus();
          }
          this.logEvent('dish', 'share.facebook', undefined, this.item.id, this.location.id);
        }
      }, {
        text: 'Twitter',
        icon: 'logo-twitter',
        handler: () => {
          const twitterWindow = window.open(
            'https://twitter.com/intent/tweet?hashtags=menu-manager&text='
            + encodeURIComponent(message) + '&url=' + encodeURIComponent(url),
            'twitter-popup',
            'height=350,width=600');
          if (twitterWindow.focus) {
            twitterWindow.focus();
          }
          this.logEvent('dish', 'share.twitter', undefined, this.item.id, this.location.id);
        }
      }, {
        text: this.translateService.instant('APP.CANCEL'),
        icon: 'close',
        role: 'cancel'
      }]
    });

    await actionSheet.present();
  }

  public async dismiss() {
    await this.modalController.dismiss();
  }
  public async addItemToBasketRequest(catalogItem: CatalogItem): Promise<void> {
    if (catalogItem.variations.length === 1) {
      await this.addItemToBasket(catalogItem, catalogItem.variations[0]);
    } else {
      const buttons: any[] = catalogItem.variations.map((variation: CatalogItemVariation) => {
        let text: string | null | undefined = DictUtils.getString(catalogItem.strings, variation.name, this.preferredLanguage, true);
        if (text === undefined) {
          text = DictUtils.getString(catalogItem.strings, variation.name, catalogItem.defaultLang);
        }
        const button: any = {
          text,
          handler: () => {
            if (variation.priceMoney) {
              this.addItemToBasket(catalogItem, variation).then();
            } else {
              this.presentItemAddedToast(this.location.id, catalogItem, variation, [], 1);
              return false;
            }
          }
        };

        return button;
      });
      buttons.push({
        text: this.translateService.instant('APP.CANCEL'),
        icon: 'close',
        role: 'cancel'
      });

      const actionSheet = await this.actionSheetController.create({
        buttons
      });

      await actionSheet.present();
    }
  }

  private async addItemToBasket(catalogItem: CatalogItem, catalogItemVariation: CatalogItemVariation, quantity: number = 1): Promise<void> {
    const showModifierScreen: boolean = catalogItem.skipModifierScreen !== true
      && catalogItem.modifierListInfos
      && catalogItem.modifierListInfos.length > 0
      && catalogItem.modifierListInfos.findIndex((info: CatalogModifierListInfo) => info.enabled) > -1;
    if (showModifierScreen) {
      const result: { role: 'ok' | 'cancel', modifiers: CatalogModifier[] } = await this.presentModifierModal(catalogItem, catalogItemVariation);
      if (result.role === 'ok') {
        await this.showLoading();
        await this.basketService.addItem(this.location.id, catalogItem, catalogItemVariation, result.modifiers, quantity);
        await this.presentItemAddedToast(this.location.id, catalogItem, catalogItemVariation, result.modifiers, quantity);
        await this.hideLoading();
      }
    } else {
      await this.showLoading();
      await this.basketService.addItem(this.location.id, catalogItem, catalogItemVariation, undefined, quantity);
      await this.presentItemAddedToast(this.location.id, catalogItem, catalogItemVariation, [], quantity);
      await this.hideLoading();
    }
  }

  private async presentModifierModal(catalogItem: CatalogItem,
                                     catalogItemVariation: CatalogItemVariation): Promise<{ role: 'ok' | 'cancel', modifiers: CatalogModifier[] }> {
    const modal = await this.modalController.create({
      component: SelectModifierPage,
      componentProps: {
        account: this.account,
        location: this.location,
        catalogItem,
        catalogItemVariation,
        modifierMap: this.modifierMap,
        preferredLanguage: this.preferredLanguage
      }
    });

    await modal.present();
    const result: OverlayEventDetail = await modal.onDidDismiss();

    return { role: result.role === 'ok' ? 'ok' : 'cancel', modifiers: result.data };
  }

  private async presentItemAddedToast(locationId: string, item: CatalogItem, variation: CatalogItemVariation, modifiers: CatalogModifier[],
                                      quantity: number): Promise<void> {
    let duration = 1200;
    let message: string = this.translateService.instant('MENU.UPON_REQUEST');
    const buttons: ToastButton[] = [];

    if (variation.priceMoney) {
      duration = 4000;
      message = this.translateService.instant('MENU.ADDED');
      buttons.push({
        side: 'end',
        handler: async () => {
          await this.presentLineItemNotePrompt(locationId, item, variation, modifiers, quantity);
        },
        text: this.translateService.instant('MENU.SET_NOTE'),
        cssClass: 'app-note-toast-button'
      }, {
        icon: 'close',
        role: 'cancel'
      });
    }
    const variationName: string | null | undefined = DictUtils.getString(item.strings, variation.name, item.defaultLang);
    message += ': ' + item.name + (variationName ? ` (${variationName})` : '');
    message += modifiers && modifiers.length > 0 ? ` + ${this.translateService.instant('MENU.MODIFIERS', { count: modifiers.length })}` : '';

    if (this.noteToast) {
      await this.noteToast.dismiss();
    }

    this.noteToast = await this.toastController.create({
      message,
      duration,
      position: 'bottom',
      buttons,
      cssClass: 'app-note-toast'
    });

    this.noteToast.onDidDismiss().then(() => {
      this.noteToast = undefined;
    });

    await this.noteToast.present();
  }

  private async presentLineItemNotePrompt(locationId: string, item: CatalogItem, variation: CatalogItemVariation, modifiers: CatalogModifier[],
                                          quantity: number): Promise<void> {
    const variationName: string | null | undefined = DictUtils.getString(item.strings, variation.name, item.defaultLang);
    let lineItemName = item.name + (variationName ? ` (${variationName})` : '');
    lineItemName += modifiers && modifiers.length > 0 ? ` + ${this.translateService.instant('MENU.MODIFIERS', { count: modifiers.length })}` : '';

    const modal = await this.modalController.create({
      component: TextEditComponent,
      componentProps: {
        subHeader: lineItemName,
        input: '',
        inputPlaceholder: await this.translateService.get(
          this.businessTypeUi.langKeyPrefix + 'MENU.SPECIAL_REQUESTS_LINE_ITEM_PLACEHOLDER'
        ).pipe(first()).toPromise()
      },
      cssClass: 'edit-modal'
    });

    modal.onDidDismiss().then((results) => {
      if (results.role === 'ok') {
        const note: string | undefined = results.data ? results.data.trim() : undefined;
//        lineItem.specialRequests = note ? note : '';
        this.basketService.setNote(locationId, item, variation, modifiers, quantity, note ? note : '');
      }
    });

    return await modal.present();
  }

  public getAvailabilityNote(location: Location, item: CatalogItem): string {
    const notAvailable: string[] = [];

    if (location.orderingDineIn && item.availableForDineIn === false) {
      notAvailable.push(this.translateService.instant('DISH_DETAILS.ORDER_DINE_IN'));
    }
    if (location.orderingPickup && item.availableForPickup === false) {
      notAvailable.push(this.translateService.instant('DISH_DETAILS.ORDER_PICKUP'));
    }
    if (location.orderingDelivery && item.availableForDelivery === false) {
      notAvailable.push(this.translateService.instant('DISH_DETAILS.ORDER_DELIVERY'));
    }

    return notAvailable.length > 0
      ? this.translateService.instant('DISH_DETAILS.ORDER_NOT_AVAILABLE', {types: notAvailable.join(', ')})
      : '';
  }
}
