import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Storage } from '@ionic/storage';

import { Customer } from '../models/customer';


/**
 * Class providing information within the current session.
 */
@Injectable({
  providedIn: 'root'
})
export class Session {

  protected readySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  protected currentCustomerSubject: ReplaySubject<Customer | undefined> = new ReplaySubject(1);
  protected valueMap: Map<string, ReplaySubject<string | null>> = new Map();

  public readonly PREFERRED_LANGUAGE = 'preferredLanguage';
  public readonly COOKIE_CONSENT = 'cookieConsent';
  public readonly ORDER_ALERT_REPEAT = 'orderAlertRepeat';

  /**
   * The default constructor.
   */
  constructor(
    private storage: Storage
  ) {
    this.currentCustomerSubject.next(undefined);
  }

  /**
   * Emit that all data is set in the SessionManager.
   */
  public setReady(): void {
    this.readySubject.next(true);
    this.readySubject.complete();
  }

  /**
   * Indicates whether SessionManager is ready.
   *
   * @returns true if SessionManager is ready
   */
  public isReady(): Observable<boolean> {
    return this.readySubject.asObservable();
  }

  /**
   * Sets current Customer.
   *
   * @param customer the Customer to set
   */
  public setCurrentCustomer(customer: Customer | undefined): void {
    this.currentCustomerSubject.next(customer);
  }

  /**
   * Returns the current Customer.
   *
   * @returns the current Customer, otherwise undefined if not existent
   */
  public getCurrentCustomer(): Observable<Customer | undefined> {
    return this.currentCustomerSubject.asObservable();
  }

  /**
   * Set value to session.
   *
   * @param key the key identifying the value
   * @param value the value to store
   * @param persistent true if value should be stored longer than the session
   * @param duration the duration of persistence in seconds (only active if persistent = true)
   */
  public async setValue(key: string, value: any | null, persistent: boolean = false, duration?: number): Promise<void> {
    if (!this.valueMap.has(key)) {
      this.valueMap.set(key, new ReplaySubject(1));
    }

    try {
      if (persistent) {
        await this.storage.set(key, JSON.stringify({ value, timestamp: new Date().toISOString(), duration, version: 1 }));
      } else {
        await this.storage.remove(key);
      }
    } catch (e) {
      // ignore
    }

    this.valueMap.get(key).next(value);
  }

  public getValue(key: string): Observable<any | null> {
    if (!this.valueMap.has(key)) {
      this.valueMap.set(key, new ReplaySubject(1));

      this.storage.get(key).then((value: any | null) => {
        let foundValue: any | null = value;  // initialize foundValue with value (instead null) due to legacy reason

        try {
          const json: { value: any, timestamp: string, duration?: number, version: number } = JSON.parse(value);
          if (json.version !== null && json.version !== undefined) {
            foundValue = json.value;

            // checks whether entry is overdue
            if (json.duration > 0 && ((new Date()).getTime() - (new Date(json.timestamp)).getTime() > json.duration * 1000)) {
              foundValue = null;
            }
          }
        } catch (e) {
          // ignore, already initialized with value
        }

        this.valueMap.get(key).next(foundValue);
      });
    }

    return this.valueMap.get(key).asObservable();
  }

  public async removeValue(key: string): Promise<void> {
    if (this.valueMap.has(key)) {
      this.valueMap.get(key).next(null);
    }

    await this.storage.remove(key);
  }
}
