import { Address } from './address';


/**
 * Controls the properties of a Location as it applies to this Customer.
 */
export class LocationInfo {

  /** The ID of the Location controlled by this LocationInfo. */
  public locationId: string;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;
  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  constructor(locationId: string) {
    this.locationId = locationId;
    this.createdAt = new Date().toISOString();
    this.updatedAt = new Date().toISOString();
  }
}

/**
 * Represents a customer profile.
 */
export class Customer {

  /** A unique ID for the customer profile. */
  public id: string;
  /** A unique firebase user ID. */
  public firebaseUserId: string;

  /**
   * Optional, other IDs used to associate the customer profile with an entity in other systems.
   * An array element has the format <REFERENCE_SERVICE>:<REFERENCE_ID>, i.e. 'GOOGLE_RESERVE:345675432'.
   */
  public refs?: string[];

  /** Indicates whether the Customer's email address has been verified. */
  public verified = false;
  /** The code for the email address verification. */
  public verificationCode?: string;

  /** The person's salutation. */
  public salutation?: string | null;
  /** The given (i.e., first) name associated with the customer profile. */
  public firstname?: string | null;
  /** The family (i.e., last) name associated with the customer profile. */
  public lastname?: string | null;
  /** The email address associated with the customer profile. */
  public email: string | null;
  /** The phone number associated with the customer profile. */
  public phoneNumber?: string;

  public photoUrl?: string | null;
  public marketingConsent = false;
  public floor?: string;

  /** A business name associated with the customer profile. */
  public companyName?: string;

  /**
   * The physical address associated with the customer profile.
   */
  public address?: Address;

  /**
   * The birthday associated with the customer profile, in RFC-3339 format.
   * Year is optional, timezone and times are not allowed.
   * For example: 0000-09-01T00:00:00-00:00 indicates a birthday on September 1st.
   * 1998-09-01T00:00:00-00:00 indications a birthday on September 1st 1998.
   */
  public birthday?: string;

//  /** Payment details of cards stored on file for the customer profile. */
//  public cards?: Card[];

  /** A custom note associated with the customer profile. */
  public note?: string;

  /** The IDs of customer groups the customer belongs to. */
  public groupIds?: string[];

  /** A set of LocationInfo objects representing the Locations visited by the Customer. */
  public visitedLocationInfos: LocationInfo[] = [];
  /** A set of LocationInfo objects representing the Locations visited by the Customer. */
  public favoredLocationInfos: LocationInfo[] = [];
  /** A set of LocationInfo objects representing the Locations recommended by platform. */
  public recommendedLocationInfos: LocationInfo[] = [];

  /** The customer's country, in ISO 3166-1-alpha-2 format. See Country for possible values. */
  public country: string;
  /** The language associated with the customer in BCP 47 format. */
  public countryCode: string;
  /**
   * The currency used for all transactions by this customer, specified in ISO 4217 format. For example, the currency for a customer
   * processing transactions in the United States is 'USD'. See Currency for possible values.
   */
  public currency: string;
  /** The IANA Timezone Database identifier for the customer's timezone. */
  public timezone: string;

  /**
   * Application-defined data attached to this order. Metadata fields are intended to store descriptive references
   * or associations with an entity in another system or store brief information about the object.
   * Square does not process this field; it only stores and returns it in relevant API calls.
   * Do not use metadata to store any sensitive information (personally identifiable information, card details, etc.).
   *
   * Keys written by applications must be 60 characters or less and must be in the character set [a-zA-Z0-9_-].
   * Entries may also include metadata generated by Square. These keys are prefixed with a namespace,
   * separated from the key with a ':' character.
   *
   * Values have a max length of 255 characters.
   *
   * An application may have up to 10 entries per metadata field.
   *
   * Entries written by applications are private and can only be read or modified by the same application.
   */
  public metadata: { [key: string]: string };

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;
  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;
}
