import { LanguageDictionary } from '../models/language-dictionary';


/**
 * Utility class for language dictionary operations.
 */
export class DictUtils {

  /**
   * Returns the text associated with the given key and language code.
   * If no text is found then the return the key as fallback.
   *
   * @param dicts the language dictionaries
   * @param key the key of the string resource
   * @param lang the language code in ISO-639-1 format
   * @param optional true if to ignore fallback
   * @returns the found text, otherwise undefined
   */
  static getString(dicts: LanguageDictionary[] | undefined, key: string, lang: string, optional: boolean = false)
    : string | null | undefined {
    let text: string | null | undefined;

    if (dicts !== undefined && key) {
      const dict: LanguageDictionary | undefined = dicts.find((dictionary: LanguageDictionary) => dictionary.lang === lang);
      if (dict !== undefined && dict.translations && dict.translations.hasOwnProperty(key)) {
        text = dict.translations[key];
      }
    }

    if (text === undefined && !optional) {
      text = key;
    }

    return text;
  }

  /**
   * Returns all owner translations associated with the given key. The default language is not included.
   * All entries have the format e.g. 'de:GERMANTEXT'
   *
   * @param dicts the language dictionaries
   * @param key the key of the string resource
   * @param defaultLangToIgnore the code of the language in ISO-639-1 format to ignore
   * @returns the found texts, otherwise empty
   */
  static getOwnerTranslations(dicts: LanguageDictionary[] | undefined, key: string, defaultLangToIgnore: string): string[] {
    const translations: string[] = [];

    if (dicts !== undefined && key) {
      for (const dict of dicts) {
        if (dict.sourceType === 'OWNER' && dict.lang !== defaultLangToIgnore && dict.translations.hasOwnProperty(key)) {
          translations.push(`${dict.lang}:${dict.translations[key]}`);
        }
      }
    }

    return translations;
  }
}
