import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';

import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';

import { Account } from '../../../shared/models/account';
import { Location } from '../../../shared/models/location';
import { CatalogItem, CatalogModifierListInfo, CatalogModifierOverride } from '../../../shared/models/catalog-item';
import { CatalogModifier } from '../../../shared/models/catalog-modifier';
import { CatalogModifierList } from '../../../shared/models/catalog-modifier-list';
import { CatalogItemVariation } from '../../../shared/models/catalog-item-variation';
import { AnalyticsManager } from '../../../shared/manager/analytics-manager.service';
import { BasePage } from '../../../shared/base.page';
import { LocationPriceUtils } from '../../../shared/utils/location-price-utils';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-select-modifier',
  templateUrl: './select-modifier.page.html',
  styleUrls: ['./select-modifier.page.scss'],
})
export class SelectModifierPage extends BasePage implements OnInit {

  public locationPriceUtils = LocationPriceUtils;

  @Input() account: Account;
  @Input() location: Location;
  @Input() catalogItem: CatalogItem;
  @Input() catalogItemVariation: CatalogItemVariation;
  @Input() modifierMap: Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }>;
  @Input() preferredLanguage: string | undefined;

  public businessTypeUi = environment.accountBusinessTypeUi.RESTAURANT;

  /** ModifierList ID to selection mapping. */
  public listSelectionMap: Map<string, SelectionModel<CatalogModifier>> = new Map();

  constructor(
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager,
    private modalController: ModalController
  ) {
    super('select_modifier', translateService, alertController, loadingController, analyticsManager);
  }

  async ngOnInit() {
    this.businessTypeUi = this.account.businessType ? environment.accountBusinessTypeUi[this.account.businessType] : this.businessTypeUi;

    this.catalogItem.modifierListInfos.forEach((info: CatalogModifierListInfo) => {
      const modifierData: { modifierList: CatalogModifierList, modifiers: CatalogModifier[]} | undefined = this.modifierMap.get(info.modifierListId);

      if (info.enabled && modifierData && modifierData.modifierList.visibility === 'visible') {
        if (modifierData.modifiers.length > 0) {
          const selectionType: string = modifierData.modifierList.selectionType;
          let initiallySelectedValues: CatalogModifier[] = [];

          if (info.modifierOverrides && info.modifierOverrides.length > 0) {
            initiallySelectedValues = modifierData.modifiers.filter((modifier: CatalogModifier) => {
              return info.modifierOverrides.findIndex((override: CatalogModifierOverride) => {
                return override.onByDefault && override.modifierId === modifier.id;
              }) > -1;
            });
          }
          this.listSelectionMap.set(info.modifierListId, new SelectionModel<CatalogModifier>(selectionType === 'MULTIPLE', initiallySelectedValues));
        }
      }
    });
  }

  public async dismiss() {
    await this.modalController.dismiss(undefined, 'cancel');
  }

  public async confirm() {
    let showError = false;
    const selected: CatalogModifier[] = [];

    for (const info of this.catalogItem.modifierListInfos) {
      const selection: SelectionModel<CatalogModifier> | undefined = this.listSelectionMap.get(info.modifierListId);

      if (selection) {
        if (selection.selected.length < info.minSelectedModifiers) {
          showError = true;
          break;
        } else {
          selected.push(...selection.selected);
        }
      }
    }

    if (showError) {
      this.showError('', this.translateService.instant('SELECT_MODIFIER.MODIFIER_LIST_HINT_MIN_ERROR'));
    } else {
      await this.modalController.dismiss(selected, 'ok');
    }
  }
}
